<template>
  <div>
    <policyForm ref="policyForm"
      :detailData="policyDetail"
      :permissions="policyDetailPermissions"
      :policySubjectList="policySubjectList"
      :policyInsuredList="policyInsuredList" />
  </div>
</template>

<script>
import policyForm from "@/views/policyManagement/components/applyClaimForm.vue";
import { getPolicyDetail } from "@/api/policy";
import { insClaimsCaseUpdate, claimsLossRremoveByCaseIds, claimsDataSheetDelete } from "@/api/claimData";
import { hasPerms, dataPermissions } from "@/utils/auth";
export default {
  props: {
    detail: {
      type: Object,
      default: () => { }
    }
  },
  components: { policyForm },
  data() {
    return {
      policyDetail: {},
      policySubjectList: [],
      policyInsuredList: [],
      policyDetailPermissions: {
        // 车架号
        frameNo: dataPermissions('M21-Edit', 'frameNo'),
        // 案件跟进人
        caseFollower: dataPermissions('M21-Edit', 'caseFollower'),
        // 现场联系人手机号
        siteContactPhone: dataPermissions('M21-Edit', 'siteContactPhone'),
        // 出险类型适用条款
        dangerTypeClause: dataPermissions('M21-Edit', 'dangerTypeClause'),
        // 业务员
        policySalesman: dataPermissions('M21-Edit', 'policySalesman'),
        // 报案人姓名
        reportCasePersonName: dataPermissions('M21-Edit', 'reportCasePersonName'),
        // 预估损失金额
        estimateLoss: dataPermissions('M21-Edit', 'estimateLoss'),
        // 保司报案号
        realCaseNo: dataPermissions('M21-Edit', 'realCaseNo'),
        // 保单号
        policyNo: dataPermissions('M21-Edit', 'policyNo'),
        // 报案人联系电话
        reportCasePhone: dataPermissions('M21-Edit', 'reportCasePhone'),
        // 设备型号
        equipmentType: dataPermissions('M21-Edit', 'equipmentType'),
        // 现场联系人姓名
        siteContactName: dataPermissions('M21-Edit', 'siteContactName'),
        // 保险公司
        policyCompany: dataPermissions('M21-Edit', 'insuranceCompany'),
        // 第二被保险人
        policySecondInsured: dataPermissions('M21-Edit', 'policySecondInsured'),
        // 出厂日期
        productionDate: dataPermissions('M21-Edit', 'productionDate'),
        // 投保省份
        policyProvince: dataPermissions('M21-Edit', 'policyProvince'),
        // 第一被保险人
        policyFirstInsured: dataPermissions('M21-Edit', 'policyFirstInsured'),
        // 报案时间
        reportCaseTime: dataPermissions('M21-Edit', 'reportCaseTime'),
        // 设备品牌
        brand: dataPermissions('M21-Edit', 'brand'),
        // 设备类型
        deviceType: dataPermissions('M21-Edit', 'deviceType'),
        // 事故原因
        accidentCause: dataPermissions('M21-Edit', 'accidentCause'),
        // 案件责任比例
        dutyRate: dataPermissions('M21-Edit', 'dutyRate'),
        // 设备受损部位
        damagedParts: dataPermissions('M21-Edit', 'damagedParts'),
        // 案件编号
        caseNo: dataPermissions('M21-Edit', 'caseNo'),
        // 小时数
        workTime: dataPermissions('M21-Edit', 'workTime'),
        // 投保方式
        policyApplicantMode: dataPermissions('M21-Edit', 'policyApplicantMode'),
        // 车架号后6位
        vinLast: dataPermissions('M21-Edit', 'vinLast'),
        // 出险时间
        dangerDate: dataPermissions('M21-Edit', 'dangerDate'),
        // 出险信息备注
        dangerRemark: dataPermissions('M21-Edit', 'dangerRemark'),
        // 险种名称
        policyInsuranceType: dataPermissions('M21-Edit', 'policyInsuranceType'),
        // 整备质量
        curbWeight: dataPermissions('M21-Edit', 'curbWeight'),
        // 投保门店
        policyApplicantStore: dataPermissions('M21-Edit', 'policyApplicantStore'),
        // 理算专员
        adjustmentAssistant: dataPermissions('M21-Edit', 'adjustmentAssistant'),
        // 区域经理
        regionalManager: dataPermissions('M21-Edit', 'regionalManager'),
        // 出险类型
        dangerTypeId: dataPermissions('M21-Edit', 'dangerTypeId'),
        // 出险详细地址
        dangerDetailAddress: dataPermissions('M21-Edit', 'dangerDetailAddress'),
        policyTime: dataPermissions('M21-Edit', 'policyTime'),
        // 所属项目
        projectName: dataPermissions('M21-Add', 'projectName'),
      }
    };
  },
  watch: {
    detail: {
      handler(v) {
        if (v.policyId) {
          this.getDetail(v.policyId);
        } else {
          this.policyDetail = {};
          this.policySubjectList = [];
          this.policyInsuredList = [];
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getDetail(id) {
      getPolicyDetail({
        id
      }).then(res => {
        this.policyDetail = {
          ...this.detail,
         //  "policyApplicantMode": res.data.applicantModeName,
         //  "policyApplicantStore": res.data.applicantStoreName,
         //  "policyCompany": res.data.acceptCompanyList && res.data.acceptCompanyList.length ? res.data.acceptCompanyList[0].companyName : '',
         //  "policyEndDate": res.data.endDate,
         //  "policyFirstInsured": res.data.policyInsuredList && res.data.policyInsuredList.length ? res.data.policyInsuredList[0].playersName : '',
         //  "policyId": res.data.id,
         //  "policyInsuranceType": res.data.policyPlanList && res.data.policyPlanList.length ? res.data.policyPlanList[0].insuranceTypeName : '',
         //  "policyNo": res.data.policyNo,
         //  "policyProvince": res.data.province,
         //  "policySalesman": res.data.salesmanName,
         //  "policySecondInsured": res.data.policyInsuredList && res.data.policyInsuredList.length > 1 ? res.data.policyInsuredList[1].playersName : '',
         //  "policyStartDate": res.data.startDate,
        };
        this.policySubjectList = res.data.policySubjectList;
        res.data.policyInsuredList && res.data.policyInsuredList.length > 1 ? res.data.policyInsuredList.splice(0,1) : '';
        this.policyInsuredList = res.data.policyInsuredList || [];
        this.originalPolicyDetail = JSON.parse(JSON.stringify(this.policyDetail));
      }).catch(err => {
        console.log(err);
      });
    },
    handleReset() {
      this.$refs.policyForm.handleReset();
      this.$forceUpdate();
    },
    async handleSave() {
      let data = await this.$refs.policyForm.handleSubmit();
      if (data.resetObj && this.policyDetail.lastStatus === 10) {
        this.$confirm(`${ data.resetObj === 'policy' ? '理算审核已通过, 保单信息不能再修改' : '理算审核已通过, 标的信息不能再修改' }`, '修改', {
          confirmButtonText: '确 定',
          cancelButtonText: '取 消',
          type: 'warning'
        }).then(() => {
          this.$emit('Submit');
        }).catch(() => {
        });
        return false;
      } else if (data.resetObj && (this.policyDetail.lastStatus > 6 || this.policyDetail.lastStatus == 3)) {
        this.$confirm(`${ data.resetObj === 'policy' ? '修改保单信息将重置定损和索赔模块, 是否继续' : '修改标的信息将重置定损模块, 是否继续' }`, '修改', {
          confirmButtonText: '继续',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          insClaimsCaseUpdate({ ...data }).then(res => {
            if (res) {
              this.$emit('Submit');
              if (data.resetObj === 'policy') {
                claimsLossRremoveByCaseIds({ caseIds: data.id });
                claimsDataSheetDelete({ caseId: data.id });
              }
              claimsLossRremoveByCaseIds({ caseIds: data.id });
              this.$message.success('编辑成功');
            }
          }).catch(err => {
            console.log(err);
          });
        }).catch(() => {
        });
      } else {
        insClaimsCaseUpdate({ ...data }).then(res => {
          if (res) {
            this.$emit('Submit');
            this.$message.success('编辑成功');
          }
        }).catch(err => {
          console.log(err);
        });
      }
    },
  }
}
</script>

<style>
</style>