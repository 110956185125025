<template>
  <div class="step-one">
    <AssociationScroll :showTabs="false">
      <AssociationScrollView title="保单信息"
        name="0">
        <el-row :gutter="10">
          <el-col :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="6"
            v-for="(item,index) in policyInformation"
            :key="index + 999">
            <div class="detail-text">
              {{ item.label }}
              <span v-if="(item.key instanceof Array)">
                <span v-for="(value, index) in item.key"
                  :key="value">{{ detail[value] }}{{ index>=item.key.length -1 ? '' : ' ~ ' }}</span>
              </span>
              <span v-else>{{ detail[item.key] }}</span>
            </div>
          </el-col>
        </el-row>
      </AssociationScrollView>
      <AssociationScrollView title="标的信息"
        name="1">
        <el-row :gutter="10">
          <el-col :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="6"
            v-for="(item,index) in subjectInformation"
            :key="index + 999">
            <div class="detail-text">
              {{ item.label }}
              <span v-if="(item.key instanceof Array)">
                <span v-for="(value, index) in item.key"
                  :key="value">{{ detail[value] }}{{ index>=item.key.length -1 ? '' : ' ~ ' }}</span>
              </span>
              <span v-else>{{ detail[item.key] }}</span>
            </div>
          </el-col>
          <el-col :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24">
            <div class="detail-text"
              style="font-size: 14px;font-weight: bold;color: #333333;">出险历史</div>
            <el-table :data="policySubjectHistoryList"
              border
              style="margin: 0;">
              <el-table-column label="序号"
                type="index"
                width="50"></el-table-column>
              <el-table-column label="保单号"
                prop="policyNo"
                width="180">
                <template slot-scope="{row}">
                  <div class="el-button polecy-button el-button--text el-button--small"
                    style="text-decoration:underline;padding: 0;"
                    @click="handleGoPath('/routerPolicyManagement/ConstructionMachinery/policyDetails/' + row.policyId)">{{ row.policyNo }}</div>
                </template>
              </el-table-column>
              <el-table-column label="案件编号"
                prop="caseNo"
                width="180">
                <template slot-scope="{row}">
                  <div class="el-button polecy-button el-button--text el-button--small"
                    style="text-decoration:underline;padding: 0;"
                    @click="handleGoPath('/routerClaimManagement/claimDetails/' + row.id)">{{ row.caseNo }}</div>
                </template>
              </el-table-column>
              <el-table-column label="报案号"
                prop="realCaseNo"
                width="180"></el-table-column>
              <el-table-column label="保险公司"
                prop="policyCompany"></el-table-column>
              <el-table-column label="出险类型"
                prop="dangerType"></el-table-column>
              <el-table-column label="出险时间"
                prop="dangerDate"></el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </AssociationScrollView>
      <AssociationScrollView title="机手信息"
        name="3">
        <el-row :gutter="10">
          <el-col :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="6"
            v-for="(item,index) in jiShouInformation"
            :key="index + 999">
            <div class="detail-text">
              {{ item.label }}
              <span v-if="item.isSwicth">
                <textSwitch v-model="detail[item.key]"
                  disabled
                  active-text="无"
                  inactive-text="有" />
              </span>
              <span v-else>{{ detail[item.key] }} {{ item.suffix }}</span>
            </div>
          </el-col>
        </el-row>
      </AssociationScrollView>
      <AssociationScrollView title="出险信息"
        name="2">
        <el-row :gutter="10">
          <el-col :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="6"
            v-for="(item,index) in dangerInformation"
            :key="index + 999">
            <div class="detail-text">
              {{ item.label }}
              <span v-if="(item.key instanceof Array)">
                <span v-for="(value, index) in item.key"
                  :key="value">{{ detail[value] }}{{ index>=item.key.length -1 ? '' : ' ~ ' }}</span>
              </span>
              <span v-if="item.isSwicth">
                <textSwitch v-model="detail[item.key]"
                  disabled
                  active-text="否"
                  inactive-text="是" />
              </span>
              <span v-else>{{ detail[item.key] }} {{ item.suffix }}</span>
            </div>
          </el-col>
          <el-col :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
            v-if="bulkPermissions.accidentCause">
            <div class="detail-text">简述事故发生原因：{{ detail.accidentCause }}</div>
          </el-col>
          <el-col :xs="24"
            :sm="24"
            :md="24"
            :lg="24"
            :xl="24"
            v-if="bulkPermissions.dangerRemark">
            <div class="detail-text">备注</div>
            <el-input type="textarea"
              placeholder="请输入内容"
              v-model="detail.dangerRemark"
              maxlength="100"
              rows="6"
              disabled
              show-word-limit></el-input>
          </el-col>
        </el-row>
      </AssociationScrollView>
      <AssociationScrollView title="一立科技硬件报警信息"
        name="7">
        <el-row :gutter="10" class="statistical-box">
          <el-table style="width: 100%" border :data="detail.hardware && detail.hardware.hardwareAlarmVO ? detail.hardware.hardwareAlarmVO : []">
        <el-table-column prop="date" label="序号" width="80" align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="alarmName"
          label="报警名称"
          align="center"
        ></el-table-column>
        <el-table-column prop="alarmType" label="报警类型" align="center">
          <template slot-scope="scope">
            {{ scope.row.alarmType == 1 ? "安全风险" : "设备风险" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="alarmSum"
          label="报警总次数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="alarmSevenSum"
          label="近7天报警次数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="alarmTodaySum"
          label="出险当日报警次数"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="alarmInterveneSum"
          label="出险当日干预次数"
          align="center"
        ></el-table-column>
      </el-table>
      <div class="statistical-box-decoration">
        <div class="statistical-box-decoration-top">
          <div class="statistical-box-decoration-top-tab">
            <div
              :class="{
                'statistical-box-decoration-top-tab-current':
                  decorationIndex != 1,
              }"
              @click="decorationIndex = 1"
            >
              附件
            </div>
            <div
              :class="{
                'statistical-box-decoration-top-tab-current':
                  decorationIndex != 2,
              }"
              @click="decorationIndex = 2"
            >
              定位
            </div>
          </div>
          <!-- <div
            class="statistical-box-decoration-top-download"
            v-if="details.attachment && details.attachment.length && isDownload"
            @click="$emit('downloadClick')"
          >
            <img src="@/assets/images/download-icon.png" alt="" srcset="" />
            <span>下载</span>
          </div> -->
        </div>
        <div
          class="statistical-box-decoration-down"
          v-show="decorationIndex == 1"
        >
          <div class="statistical-box-decoration-down-box">
            <div class="statistical-box-decoration-down-box-title">
              <div>出险前影像资料</div>
              <div
                class="statistical-box-decoration-top-download"
                v-if="
                  detail.hardware && detail.hardware.hardwareAlarmAttachVO &&
                  detail.hardware.hardwareAlarmAttachVO.length"
                @click="downLoadClick"
              >
                <img src="@/assets/images/download-icon.png" alt="" srcset="" />
                <span>下载附件</span>
              </div>
            </div>
            <div
              v-for="(item, index) in (detail.hardware && detail.hardware.hardwareAlarmAttachVO ? detail.hardware.hardwareAlarmAttachVO : [])"
              style="width: 100%"
              :key="index"
            >
              <div class="statistical-box-decoration-down-box-time">
                {{ item[0].createTime }}
              </div>
              <div style="display: flex">
                <el-image
                  class="statistical-box-decoration-down-content"
                  v-for="(item2, index2) in item.filter(
                    (val) => val.filePath.indexOf('.mp4') == -1
                  )"
                  :key="index2"
                  :src="item2.filePath"
                  :preview-src-list="
                    item
                      .filter((val) => val.filePath.indexOf('.mp4') == -1)
                      .map((val) => val.filePath)
                  "
                >
                </el-image>

                <DialogVideo
                  class="statistical-box-decoration-down-content"
                  :videoSrc="item2.filePath"
                  v-for="(item2, index2) in item.filter(
                    (val) => val.filePath.indexOf('.mp4') > -1
                  )"
                  :key="index2 + 999"
                ></DialogVideo>
              </div>
              <!-- <video class="statistical-box-decoration-down-content" controls :src="item" v-for="(item,index) in details.videoAttachment" :key="index + 999"></video> -->
              <img
                src="@/assets/images/zwsj-image.png"
                v-if="
                  !detail.hardware.hardwareAlarmAttachVO ||
                  !detail.hardware.hardwareAlarmAttachVO.length
                "
                style="height: 170px; margin: auto"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
        <div v-show="decorationIndex == 2" style="padding:16px;" class="statistical-box-decoration-down">
          <div class="statistical-box-decoration-down-box-title" style="margin-bottom:16px">
            <div>出险时刻定位</div>
          </div>
          <div
            class="statistical-box-decoration-down" style="border:none !important;overflow: initial;height: 235px;"
            ref="container"
          ></div>
        </div>
      </div>
        </el-row>
      </AssociationScrollView>
      <AssociationScrollView title="报案信息"
        name="8">
        <el-row :gutter="10">
          <el-col :xs="24"
            :sm="12"
            :md="12"
            :lg="8"
            :xl="6"
            v-for="(item,index) in reportCaseInformation"
            :key="index + 999">
            <div class="detail-text">
              {{ item.label }}
              <span v-if="(item.key instanceof Array)">
                <span v-for="(value, index) in item.key"
                  :key="value">{{ detail[value] }}{{ index>=item.key.length -1 ? '' : ' ~ ' }}</span>
              </span>
              <span v-else>{{ detail[item.key] }}</span>
            </div>
          </el-col>
        </el-row>
      </AssociationScrollView>
    </AssociationScroll>
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import textSwitch from "@/components/textSwitch/index.vue";
import { insClaimsCasePage } from "@/api/claimData";
import { hasPerms, dataPermissions } from "@/utils/auth";
import DialogVideo from "@/components/DialogVideo";
import { downloadZIP } from "@/utils/index";
export default {
  components: { AssociationScroll, AssociationScrollView, textSwitch, DialogVideo },
  props: {
    detail: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    policyInformation() {
      return this.showContentData.policyData.filter(val => (val.key instanceof Array || !(val.isExist && !this.detail[val.key])) && val.isAuthority);
    },
    subjectInformation() {
      return this.showContentData.subjectData.filter(val => (val.key instanceof Array || !(val.isExist && !this.detail[val.key])) && val.isAuthority);
    },
    dangerInformation() {
      return this.showContentData.dangerData.filter(val => (val.key instanceof Array || !(val.isExist && !this.detail[val.key])) && val.isAuthority);
    },
    jiShouInformation() {
      return this.showContentData.jiShouData.filter(val => (val.key instanceof Array || !(val.isExist && !this.detail[val.key])) && val.isAuthority);
    },
    reportCaseInformation() {
      return this.showContentData.reportCaseData.filter(val => (val.key instanceof Array || !(val.isExist && !this.detail[val.key])) && val.isAuthority);
    }
  },
  data() {
    return {
      showContentData: {
        policyData: [
          {
            label: '保单号：',
            key: 'policyNo',
            isAuthority: dataPermissions('M21-View', 'policyNo')
          },
          {
            label: '第一被保险人：',
            key: 'policyFirstInsured',
            isAuthority: dataPermissions('M21-View', 'policyFirstInsured')
          },
          {
            label: '第二被保险人：',
            key: 'policySecondInsured',
            isAuthority: dataPermissions('M21-View', 'policySecondInsured'),
            isExist: !this.detail.policySecondInsured
          },
          {
            label: '业务员：',
            key: 'policySalesman',
            isAuthority: dataPermissions('M21-View', 'policySalesman')
          },
          {
            label: '保障期限：',
            key: ['policyStartDate', 'policyEndDate'],
            isAuthority: true
            // dataPermissions('M21-View', 'dueTime')
          },
          {
            label: '投保省份：',
            key: 'policyProvince',
            isAuthority: dataPermissions('M21-View', 'policyProvince')
          },
          {
            label: '险种名称：',
            key: 'policyInsuranceType',
            isAuthority: dataPermissions('M21-View', 'policyInsuranceType')
          },
          {
            label: '保险公司：',
            key: 'policyCompany',
            isAuthority: dataPermissions('M21-View', 'insuranceCompany')
          },
          {
            label: '投保方式：',
            key: 'policyApplicantMode',
            isAuthority: dataPermissions('M21-View', 'policyApplicantMode')
          },
          {
            label: '投保门店：',
            key: 'policyApplicantStore',
            isAuthority: dataPermissions('M21-View', 'policyApplicantStore')
          },
          {
            label: '所属项目：',
            key: 'projectName',
            isAuthority: dataPermissions('M21-Add', 'projectName')
          },
          {
            label: '区域经理：',
            key: 'regionalManager',
            isAuthority: dataPermissions('M21-View', 'regionalManager')
          },
          {
            label: '跟进人：',
            key: 'caseFollower',
            isAuthority: dataPermissions('M21-View', 'caseFollower')
          },
          {
            label: '理算员：',
            key: 'adjustmentAssistant',
            isAuthority: dataPermissions('M21-View', 'adjustmentAssistant')
          },
        ],
        subjectData: [
          {
            label: '出险设备：',
            key: 'frameNo',
            isAuthority: dataPermissions('M21-View', 'frameNo')
          },
          {
            label: '设备类型：',
            key: 'deviceType',
            isAuthority: dataPermissions('M21-View', 'deviceType')
          },
          {
            label: '品牌：',
            key: 'brand',
            isAuthority: dataPermissions('M21-View', 'brand')
          },
          {
            label: '型号：',
            key: 'equipmentType',
            isAuthority: dataPermissions('M21-View', 'equipmentType')
          },
          {
            label: '车架号：',
            key: 'frameNo',
            isAuthority: dataPermissions('M21-View', 'frameNo')
          },
          {
            label: '整备质量：',
            key: 'curbWeight',
            isAuthority: dataPermissions('M21-View', 'curbWeight')
          },
          {
            label: '出厂日期：',
            key: 'productionDate',
            isAuthority: dataPermissions('M21-View', 'productionDate')
          },
          {
            label: '小时数：',
            key: 'workTime',
            isAuthority: dataPermissions('M21-View', 'workTime')
          },
          {
            label: '报案当天连续作业工时：',
            key: 'workTimeDay',
            isAuthority: dataPermissions('M21-View', 'workTime')
          },
          {
            label: '近30天日均作业工时：',
            key: 'workTimeAverageMonth',
            isAuthority: dataPermissions('M21-View', 'workTime')
          },
        ],
        jiShouData: [
          {
            label: '机手姓名：',
            key: 'jiShouName',
            isAuthority: dataPermissions('M21-View', 'dangerType')
          },
          {
            label: '机手电话：',
            key: 'jiShouPhone',
            isAuthority: dataPermissions('M21-View', 'dangerType')
          },
          {
            label: '是否有操作证：',
            key: 'isControls',
            isSwicth: true,
            isAuthority: dataPermissions('M21-View', 'dangerType')
          },
          {
            label: '机手年龄：',
            key: 'jiShouAge',
            isAuthority: dataPermissions('M21-View', 'dangerType')
          },
          {
            label: '机手工龄：',
            key: 'jiShouExperience',
            isAuthority: dataPermissions('M21-View', 'dangerType')
          },
          {
            label: '机手证件号：',
            key: 'jiShouIdNumber',
            isAuthority: dataPermissions('M21-View', 'dangerType')
          },
             
        ],
        dangerData: [
          {
            label: '出险类型：',
            key: 'dangerType',
            isAuthority: dataPermissions('M21-View', 'dangerType')
          },
          {
            label: '出险类型细分：',
            key: 'dangerTypeDetails',
            isAuthority: dataPermissions('M21-View', 'dangerType')
          },
          {
            label: '事故类型：',
            key: 'accidentType',
            isAuthority: dataPermissions('M21-View', 'dangerType')
          },
          {
            label: '损失种类：',
            key: 'lossType',
            isAuthority: dataPermissions('M21-View', 'dangerType')
          },
          {
            label: '出险地点：',
            key: ['dangerProvince', 'dangerCity', 'dangerCounty'],
            isAuthority: true
          },
          {
            label: '详细地址：',
            key: 'dangerDetailAddress',
            isAuthority: true,
            isAuthority: dataPermissions('M21-View', 'dangerDetailAddress')
          },
          {
            label: '所属省份：',
            key: 'dangerProvince',
            isAuthority: true,
            isAuthority: dataPermissions('M21-View', 'dangerDetailAddress')
          },
          {
            label: '出险时间：',
            key: 'dangerDate',
            isAuthority: dataPermissions('M21-View', 'dangerDate')
          },
          {
            label: '案件责任比例：',
            suffix: '%',
            key: 'dutyRate',
            isAuthority: dataPermissions('M21-View', 'dutyRate')
          },
          {
            label: '出险类型适用保险条款：',
            key: 'dangerTypeClause',
            isAuthority: dataPermissions('M21-View', 'dangerTypeClause')
          },
          {
            label: '设备受损部位：',
            key: 'damagedParts',
            isAuthority: dataPermissions('M21-View', 'damagedParts')
          },
          {
            label: '预估损失金额：',
            key: 'estimateLoss',
            isAuthority: dataPermissions('M21-View', 'estimateLoss')
          },
          {
            label: '现场联系人：',
            key: 'siteContactName',
            isAuthority: dataPermissions('M21-View', 'siteContactName')
          },
          {
            label: '现场联系电话：',
            key: 'siteContactPhone',
            isAuthority: dataPermissions('M21-View', 'siteContactPhone')
          },
          {
            label: '报案时刻现场天气：',
            key: 'weather',
            isAuthority: dataPermissions('M21-View', 'dangerType')
          },
          {
            label: '简述事故发生原因：',
            key: 'accidentCause',
            isAuthority: dataPermissions('M21-View', 'dangerType')
          },
          {
            label: '是否需要施救：',
            key: 'rescue',
            isAuthority: true,
            isSwicth: true,
          },
          {
            label: '三者案件有无三方证明：',
            key: 'prove',
            isAuthority: true,
            isSwicth: true,
          },
          {
            label: '是否一次事故造成损失：',
            key: 'secondaryAccident',
            isAuthority: true,
            isSwicth: true,
          },
          {
            label: '非一次事故造成损失说明：',
            key: 'notOneDamagedDesc',
            isAuthority: true,
            isExist: !this.detail.secondaryAccident
          },
        ],
        reportCaseData: [
          {
            label: '报案人姓名：',
            key: 'reportCasePersonName',
            isAuthority: dataPermissions('M21-View', 'reportCasePersonName')
          },
          {
            label: '报案人电话：',
            key: 'reportCasePhone',
            isAuthority: dataPermissions('M21-View', 'reportCasePhone')
          },
          {
            label: '报案时间：',
            key: 'reportCaseTime',
            isAuthority: dataPermissions('M21-View', 'reportCaseTime')
          },
          {
            label: '保司报案号：',
            key: 'realCaseNo',
            isAuthority: dataPermissions('M21-View', 'realCaseNo')
          },
          {
            label: '案件编号：',
            key: 'caseNo',
            isAuthority: dataPermissions('M21-View', 'caseNo')
          },
          {
            label: '出险设备车架号后8位：',
            key: 'vinLast',
            isAuthority: dataPermissions('M21-View', 'vinLast'),
            isExist: !this.detail.subjectUnknown
          },
        ]
      },
      bulkPermissions: {
        dangerRemark: dataPermissions('M21-View', 'dangerRemark'),
        accidentCause: dataPermissions('M21-View', 'accidentCause'),
      },
      policySubjectHistoryList: [],
      decorationIndex: 1,
      mapall: null,
      marker: null,
    };
  },
  watch: {
    detail: {
      handler(v) {
        if (v.frameNo) {
          this.handleGetInit();
        } else {
          this.policySubjectHistoryList = [];
        }
      },
      deep: true,
      immediate: true
    },
    decorationIndex: {
      handler(v) {
        if (v == 2) {
          this.initMap();
        }
      },
    },
  },
  methods: {
    handleGetInit() {
      insClaimsCasePage({
        frameNo: this.detail.frameNo,
        excludeCaseId: this.detail.id ? [this.detail.id] : [],
        current: 1,
        size: 999
      }).then(res => {
        if (res.data && res.data.records && res.data.records.length) {
          this.policySubjectHistoryList = res.data.records;
        } else {
          this.policySubjectHistoryList = [];
        }
      });
    },
    handleGoPath(path) {
      this.$router.push({ path });
    },
    downLoadClick() {
      let urls = []
      if (!this.detail.hardware.hardwareAlarmAttachVO || !this.detail.hardware.hardwareAlarmAttachVO.length) {
        this.$message.error('暂无附件可下载');
        return false;
      }
      this.detail.hardware.hardwareAlarmAttachVO.map((val,ind)=>{
        val.map((val2,ind2)=> {
          urls.push({
            url: val2.filePath,
            name:  val2.filePath.split('/')[val2.filePath.split('/').length -1 ]
          });
          //  + '.' + val2.filePath.split('.')[val2.filePath.split('.').length -1 ]
          if (ind == this.detail.hardware.hardwareAlarmAttachVO.length -1 && ind2 == this.detail.hardware.hardwareAlarmAttachVO[ind].length - 1) {
            this.$nextTick(()=>{
              downloadZIP(urls, `${ this.detail.hardware.hardwareVehicleVO.plate }-${this.details.caseFromSourceType == 1 ? "小程序报案" : "终端报案"}-${this.detail.hardware.hardwareVehicleVO.accidentTime}.zip`);
            })
          }
        })
      })
    },
    initMap() {
      const that = this;
      let Lng = "";
      let Lat = "";
      if (
        this.detail.hardware && this.detail.hardware.hardwareAlarmVO &&
        this.detail.hardware.hardwareAlarmVO.length
      ) {
        Lng = this.detail.hardware.hardwareAlarmVO[0].lon;
        Lat = this.detail.hardware.hardwareAlarmVO[0].lat;
      }else {
        this.$message.error('暂无报警定位信息')
        return false;
      }
      var content = [
          `<div style='display:flex;width:100%;margin-bottom:16px;min-width:230px;'><div style='font-size:14px;color: #999999;margin-right:12px;'>设备名称</div><div style='font-size:14px;color: #333;'>${ this.detail.hardware.hardwareVehicleVO.plate }</div></div>`,
          `<div style='display:flex;width:100%;margin-bottom:16px;min-width:230px;'><div style='font-size:14px;color: #999999;margin-right:12px;'>报警类型</div><div style='font-size:14px;color: #333;'>${ this.detail.hardware.hardwareAlarmVO[0] ? this.detail.hardware.hardwareAlarmVO[0].alarmName : '-' }</div></div>`,
          `<div style='display:flex;width:100%;margin-bottom:16px;min-width:230px;'><div style='font-size:14px;color: #999999;margin-right:12px;'>报警时间</div><div style='font-size:14px;color: #333;'>${ this.detail.hardware.hardwareAlarmVO[0] ? this.detail.hardware.hardwareAlarmVO[0].createTime : '-' }</div></div>`,
          `<div style='display:flex;width:100%;min-width:230px;'><div style='font-size:14px;color: #999999;margin-right:12px;'>具体地址</div><div style='font-size:14px;color: #333;'>${ this.detail.hardware.hardwareAlarmVO[0] ? this.detail.hardware.hardwareAlarmVO[0].alarmProvince : '-' }${ this.detail.hardware.hardwareAlarmVO[0] ? this.detail.hardware.hardwareAlarmVO[0].alarmCity : '-' }${ this.detail.hardware.hardwareAlarmVO[0] ? this.detail.hardware.hardwareAlarmVO[0].alarmArea : '-' }</div></div>`
      ];

      // 创建 infoWindow 实例	
      var infoWindow = new AMap.InfoWindow({
        content: content.join(""),  //传入 dom 对象，或者 html 字符串
        offset: new AMap.Pixel(0, -32)
      });
        
      if (that.mapall) {
        that.mapall.setCenter([Lng, Lat]);
        that.mapall.setZoom(15);
        that.mapall.remove(this.marker);
        // 创建一个 Marker 实例：
        this.marker = new AMap.Marker({
          position: new AMap.LngLat(Lng, Lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          icon: "//yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/positioning-marker.png", // 添加 Icon 图标 URL
          offset: new AMap.Pixel(-20, -40),
        });

        // 将创建的点标记添加到已有的地图实例：
        that.mapall.add(this.marker);
        // 打开信息窗体
        infoWindow.open(that.mapall, [Lng, Lat]);
      } else {
        var map = new AMap.Map(that.$refs.container, {
          resizeEnable: true,
          center: [Lng, Lat],
          zoom: 15,
        });
        that.mapall = map;
        // 创建一个 Marker 实例：
        this.marker = new AMap.Marker({
          position: new AMap.LngLat(Lng, Lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          icon: "//yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/positioning-marker.png", // 添加 Icon 图标 URL
          offset: new AMap.Pixel(-20, -40),
        });

        // 将创建的点标记添加到已有的地图实例：
        that.mapall.add(this.marker);
        // 打开信息窗体
        infoWindow.open(that.mapall, [Lng, Lat]);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-text {
  font-size: 14px;
  color: #333;
  margin-top: 20px;
}
</style>
<style lang="scss">
.step-one {
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    // overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__body {
    tr {
      position: relative;
    }
    // .table-del {
    //   position: absolute;
    //   top: 50%;
    //   right: -50px;
    //   transform: translateY(-50%);
    //   font-size: 30px;
    //   color: #c94242;
    //   cursor: pointer;
    // }
    .el-form-item {
      margin: 0;
    }
  }
  .plan-table {
    .has-gutter,
    .el-table__fixed-header-wrapper {
      tr {
        overflow: hidden;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
  }
  .statistical-box {
    // width: 820px;
    padding: 24px 6px;
    &-title {
      position: relative;
      padding-left: 13px;
      &-text {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      &-time {
        color: #cccccc;
        font-size: 12px;
      }
    }
    &-title::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background: #4278c9;
      border-radius: 3px;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 24px;
      > div {
        width: 46%;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        > div {
          width: 112px;
          color: #999;
          margin-bottom: 23px;
          margin-right: 16px;
          text-align: right;
        }
        span {
          flex: 1;
          color: #333333;
        }
      }
    }
    &-decoration {
      &-top {
        display: flex;
        justify-content: space-between;
        &-tab {
          display: flex;
          div {
            width: 88px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 14px;
            color: #333333 !important;
            cursor: pointer;
            border-radius: 8px 8px 0px 0px;
            border: 1px solid #cccccc !important;
            border-bottom: none;
          }
          &-current {
            color: #333 !important;
            border: 1px solid #dee3e6 !important;
            background: #dee3e6 !important;;
          }
        }
        &-download {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #0080ff;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            margin-left: 2px;
            text-decoration: underline;
          }
        }
      }
      &-down {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-around;
        padding: 16px 0;
        height: 290px;
        overflow: auto;
        border-radius: 0px 8px 8px 8px;
        border: 1px solid #dee3e6 !important;
        &-content {
          width: 128px;
          height: 80px;
          margin-bottom: 8px;
          margin-left: 15px;
          border-radius: 4px;
        }
        &-box {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          &-time {
            width: 153px;
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            color: #ffffff;
            padding-left: 8px;
            margin: 8px;
            margin-left: 16px;
            background: #4278c9;
          }
          &-title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            padding: 0 16px;
            margin-bottom: 8px;
          }
        }
      }
    }
    .el-table {
      margin-bottom: 22px;
      .has-gutter {
        tr {
          .el-table__cell {
            background-color: #f2f7fd;
            font-size: 14px;
            color: #666666;
            font-weight: 400;
          }
          .is-leaf {
            border-bottom: 1px solid #dee3e6;
          }
        }
      }
      tr {
        .el-table__cell {
          font-size: 14px;
          color: #333;
          font-weight: 400;
        }
        .is-leaf {
          border-bottom: 1px solid #dee3e6;
        }
      }
    }
    .amap-info-content {
      padding: 16px 24px;
      background-color: rgba(255,255,255,.9);
      box-shadow: 0px 3px 6px 1px rgba(51,51,51,0.1);
      border-radius: 10px;
    }
    .amap-info-sharp {
      display: none;
    }
  }
}
</style>