<template>
  <div class="el-collapse-box">
    <el-form
      :model="ruleForm"
      :rules="rules"
      inline
      ref="ruleForm"
      label-position="top"
      label-width="100px"
      class="el-collapse-box"
    >
      <AssociationScroll :showTabs="false">
        <AssociationScrollView title="保单信息" name="0">
          <div class="container container-box">
            <el-form-item
              label="保单号："
              prop="policyNo"
              ref="policyNo"
              v-if="permissions.policyNo"
            >
              <el-autocomplete
                class="inline-input"
                v-model="ruleForm.policyNo"
                :fetch-suggestions="querySearch"
                placeholder="请输入保单号"
                :disabled="policyDisabled"
                @select="handleSelect"
                @blur="handlePolicyBlur"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item
              label="第一被保险人："
              prop="policyFirstInsured"
              v-if="permissions.policyFirstInsured"
            >
              <el-input
                v-model="ruleForm.policyFirstInsured"
                :disabled="
                  $route.query.orderType != 8 && ruleForm.orderType != 8
                "
                placeholder="根据保单号自动带出，无需填写"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="第二被保险人："
              prop="policySecondInsured"
              v-if="ruleForm.policySecondInsured"
            >
              <el-select
                v-model="ruleForm.policySecondInsured"
                filterable
                v-if="$route.query.orderType != 8 && ruleForm.orderType != 8"
                placeholder="请选择"
              >
                <el-option
                  v-for="dictItem in policyInsuredList"
                  :key="dictItem.id"
                  :label="dictItem.playersName"
                  :value="dictItem.playersName"
                ></el-option>
              </el-select>
              <el-input
                v-model="ruleForm.policySecondInsured"
                v-else
                placeholder="根据保单号自动带出，无需填写"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="业务员："
              prop="policySalesman"
              v-if="permissions.policySalesman"
            >
              <el-input
                v-model="ruleForm.policySalesman"
                disabled
                placeholder="根据保单号自动带出，无需填写"
              ></el-input>
            </el-form-item>
            <el-form-item label="保障期限：" prop="policyTime">
              <el-date-picker
                v-model="ruleForm.policyTime"
                type="daterange"
                range-separator=","
                start-placeholder="根据保单号自动带出"
                format="yyyy / MM / dd"
                end-placeholder="无需填写"
                value-format="yyyy-MM-dd"
                :disabled="
                  $route.query.orderType != 8 && ruleForm.orderType != 8
                "
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              label="投保省份："
              prop="policyProvince"
              v-if="permissions.policyProvince"
            >
              <el-input
                v-model="ruleForm.policyProvince"
                :disabled="
                  $route.query.orderType != 8 && ruleForm.orderType != 8
                "
                placeholder="根据保单号自动带出，无需填写"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="险种名称："
              prop="policyInsuranceType"
              v-if="permissions.policyInsuranceType"
            >
              <el-input
                v-model="ruleForm.policyInsuranceType"
                :disabled="
                  $route.query.orderType != 8 && ruleForm.orderType != 8
                "
                placeholder="根据保单号自动带出，无需填写"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="保险公司："
              prop="policyCompany"
              v-if="permissions.policyCompany"
            >
              <el-input
                v-model="ruleForm.policyCompany"
                :disabled="
                  $route.query.orderType != 8 && ruleForm.orderType != 8
                "
                placeholder="根据保单号自动带出，无需填写"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="投保方式："
              prop="policyApplicantMode"
              v-if="permissions.policyApplicantMode"
            >
              <el-input
                v-model="ruleForm.policyApplicantMode"
                :disabled="
                  $route.query.orderType != 8 && ruleForm.orderType != 8
                "
                placeholder="根据保单号自动带出，无需填写"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="投保门店："
              prop="policyApplicantStore"
              v-if="
                permissions.policyApplicantStore &&
                $route.query.orderType != 8 &&
                ruleForm.orderType != 8
              "
            >
              <!-- <el-input v-model="ruleForm.policyApplicantStore"
                disabled
                placeholder="根据保单号自动带出，无需填写"></el-input> -->
              <el-select
                v-model="ruleForm.policyApplicantStore"
                filterable
                placeholder="请选择"
              >
                <!-- @change="handleChangeSelect($event, ruleForm, 'policyApplicantStore')" -->
                <el-option
                  v-for="dictItem in applicantStoreList"
                  :key="dictItem.dictKey"
                  :label="dictItem.dictValue"
                  :value="dictItem.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="所属项目："
              v-if="
                $route.query.orderType != 8 &&
                ruleForm.orderType != 8 &&
                permissions.projectName
              "
              prop="projectName"
            >
              <el-input
                v-model="ruleForm.projectName"
                disabled
                placeholder="根据保单号自动带出，无需填写"
              ></el-input>
            </el-form-item>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="标的信息" name="1">
          <div class="container container-box">
            <el-form-item
              label="出险设备："
              prop="equipment"
              ref="equipment"
              class="container-box-custom-label"
            >
              <template slot="label">
                <div class="container-box-custom-label-box">
                  <span>标的信息</span>
                  <el-checkbox
                    v-model="ruleForm.subjectUnknown"
                    @change="changeSubjectUnknown"
                    >不能确认</el-checkbox
                  >
                </div>
              </template>
              <el-select
                v-model="ruleForm.equipment"
                placeholder="请选择"
                filterable
                @change="handleChangeEquipment"
              >
                <el-option
                  v-for="(item, index) in policySubjectList"
                  :key="index"
                  :label="item.frameNo"
                  :value="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="设备类型："
              prop="deviceType"
              v-if="permissions.deviceType"
            >
              <!-- <el-input v-model="ruleForm.deviceType"
                disabled
                placeholder="根据出险设备自动带出，无需填写"></el-input> -->
              <el-select
                v-model="ruleForm.deviceType"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="dictItem in vehicleTypeList"
                  :key="dictItem.dictKey"
                  :label="dictItem.dictValue"
                  :value="dictItem.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="品牌：" prop="brand" v-if="permissions.brand">
              <!-- <el-input v-model="ruleForm.brand"
                disabled
                placeholder="根据出险设备自动带出，无需填写"></el-input> -->
              <el-select
                v-model="ruleForm.brand"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="dictItem in vehicleBrandList"
                  :key="dictItem.dictKey"
                  :label="dictItem.dictValue"
                  :value="dictItem.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="型号："
              prop="equipmentType"
              v-if="permissions.equipmentType"
            >
              <el-input
                v-model="ruleForm.equipmentType"
                placeholder="根据出险设备自动带出，无需填写"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="车架号："
              prop="frameNo"
              v-if="permissions.frameNo"
            >
              <el-input
                v-model="ruleForm.frameNo"
                :disabled="
                  $route.query.orderType != 8 && ruleForm.orderType != 8
                "
                placeholder="根据出险设备自动带出，无需填写"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="整备质量："
              prop="curbWeight"
              v-if="permissions.curbWeight"
            >
              <el-input
                v-model="ruleForm.curbWeight"
                :disabled="
                  $route.query.orderType != 8 && ruleForm.orderType != 8
                "
                placeholder="根据出险设备自动带出，无需填写"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="出厂日期："
              prop="productionDate"
              v-if="permissions.productionDate"
            >
              <el-input
                v-model="ruleForm.productionDate"
                :disabled="
                  $route.query.orderType != 8 && ruleForm.orderType != 8
                "
                placeholder="根据出险设备自动带出，无需填写"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="小时数："
              prop="workTime"
              ref="workTime"
              v-if="permissions.workTime"
            >
              <el-input
                v-model="ruleForm.workTime"
                placeholder="请填写"
                @input="input_num($event, ruleForm, 'workTime', 9999999, 0)"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="报案当天连续作业工时："
              prop="workTimeDay"
              ref="workTimeDay"
              v-if="permissions.workTime"
            >
              <el-input
                v-model="ruleForm.workTimeDay"
                disabled
                placeholder="根据出险设备自动带出，无需填写"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="近30天日均作业工时："
              prop="workTimeAverageMonth"
              ref="workTimeAverageMonth"
              v-if="permissions.workTime"
            >
              <el-input
                v-model="ruleForm.workTimeAverageMonth"
                disabled
                placeholder="根据出险设备自动带出，无需填写"
              ></el-input>
            </el-form-item>
            <div style="font-size: 14px; font-weight: bold; color: #333333">
              出险历史
            </div>
            <el-table :data="policySubjectHistoryList" border style="margin: 0">
              <el-table-column
                label="序号"
                type="index"
                width="50"
              ></el-table-column>
              <el-table-column
                label="保单号"
                prop="policyNo"
                width="180"
              ></el-table-column>
              <el-table-column
                label="案件编号"
                prop="caseNo"
                width="180"
              ></el-table-column>
              <el-table-column
                label="报案号"
                prop="realCaseNo"
                width="180"
              ></el-table-column>
              <el-table-column
                label="保险公司"
                prop="policyCompany"
              ></el-table-column>
              <el-table-column
                label="出险类型"
                prop="dangerType"
              ></el-table-column>
              <el-table-column
                label="出险时间"
                prop="dangerDate"
              ></el-table-column>
            </el-table>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="机手信息" name="5">
          <div class="container container-box">
            <el-form-item
              label="机手姓名："
              prop="jiShouName"
              ref="jiShouName"
              v-if="permissions.dutyRate"
            >
              <el-input v-model="ruleForm.jiShouName" placeholder="请填写">
              </el-input>
            </el-form-item>
            <el-form-item
              label="机手电话："
              prop="jiShouPhone"
              ref="jiShouPhone"
              v-if="permissions.dutyRate"
            >
              <el-input
                v-model="ruleForm.jiShouPhone"
                placeholder="请填写"
                maxlength="11"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="是否有操作证："
              prop="isControls"
              ref="isControls"
              v-if="permissions.dutyRate"
            >
              <textSwitch
                v-model="ruleForm.isControls"
                active-text="无"
                inactive-text="有"
              />
            </el-form-item>
            <el-form-item
              label="机手年龄："
              prop="jiShouAge"
              ref="jiShouAge"
              v-if="permissions.dutyRate"
            >
              <el-input
                v-model="ruleForm.jiShouAge"
                placeholder="请填写"
                @input="input_num($event, ruleForm, 'jiShouAge', 100)"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="机手工龄："
              prop="jiShouExperience"
              ref="jiShouExperience"
              v-if="permissions.dutyRate"
            >
              <el-input
                v-model="ruleForm.jiShouExperience"
                placeholder="请填写"
                @input="input_num($event, ruleForm, 'jiShouExperience', 100)"
              >
              </el-input>
            </el-form-item>
            <el-form-item
              label="机手证件号："
              prop="jiShouIdNumber"
              ref="jiShouIdNumber"
              v-if="permissions.dutyRate"
            >
              <el-input v-model="ruleForm.jiShouIdNumber" placeholder="请填写">
              </el-input>
            </el-form-item>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="出险信息" name="6">
          <div class="container container-box">
            <el-form-item
              label="出险类型："
              prop="dangerTypeId"
              ref="dangerTypeId"
              class="container-box-custom-label"
              v-if="permissions.dangerTypeId"
            >
              <el-select
                v-model="ruleForm.dangerTypeId"
                @change="
                  handleChangeSelect(
                    $event,
                    ruleForm,
                    dangerTypeList,
                    'dangerType',
                    'dictValue'
                  )
                "
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="dictItem in dangerTypeList"
                  :key="dictItem.dictKey"
                  :label="dictItem.dictValue"
                  :value="dictItem.dictKey"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="出险类型细分："
              prop="dangerTypeDetails"
              ref="dangerTypeDetails"
              class="container-box-custom-label"
              v-if="permissions.dangerTypeId"
            >
              <el-select
                v-model="ruleForm.dangerTypeDetails"
                @change="
                  handleChangeSelect(
                    $event,
                    ruleForm,
                    dangerTypeDetailsList,
                    'dangerTypeDetails',
                    'dictValue'
                  )
                "
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="dictItem in dangerTypeDetailsList"
                  :key="dictItem.dictKey"
                  :label="dictItem.dictValue"
                  :value="dictItem.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="事故类型："
              prop="accidentType"
              ref="accidentType"
              class="container-box-custom-label"
              v-if="permissions.dangerTypeId"
            >
              <el-select
                v-model="ruleForm.accidentType"
                @change="
                  handleChangeSelect(
                    $event,
                    ruleForm,
                    accidentTypeList,
                    'accidentType',
                    'dictValue'
                  )
                "
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="dictItem in accidentTypeList"
                  :key="dictItem.dictKey"
                  :label="dictItem.dictValue"
                  :value="dictItem.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="损失种类："
              prop="lossType"
              ref="lossType"
              class="container-box-custom-label"
              v-if="permissions.dangerTypeId"
            >
              <el-select
                v-model="ruleForm.lossType"
                @change="
                  handleChangeSelect(
                    $event,
                    ruleForm,
                    lossTypeList,
                    'lossType',
                    'dictValue'
                  )
                "
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="dictItem in lossTypeList"
                  :key="dictItem.dictKey"
                  :label="dictItem.dictValue"
                  :value="dictItem.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="详细地址："
              prop="dangerDetailAddress"
              ref="dangerDetailAddress"
              v-if="permissions.dangerDetailAddress"
            >
              <el-input
                v-model="ruleForm.dangerDetailAddress"
                maxlength="64"
                placeholder="请填写"
                class="input-position"
              >
                <div slot="suffix" class="input-suffix">
                  <img
                    src="@/assets/images/policy/policyPosition.png"
                    @click="isPositionDialog = true"
                    style="width: 20px; height: 20px"
                    alt
                    srcset
                  />
                </div>
              </el-input>
            </el-form-item>
            <el-form-item
              label="所属省份："
              prop="dangerDetailAddress"
              ref="dangerDetailAddress"
              v-if="permissions.dangerDetailAddress"
            >
              <v-distpicker
                :province="ruleForm.dangerProvince"
                only-province
                @selected="onSelected($event, 'dangerProvince')"
              ></v-distpicker>
            </el-form-item>
            <el-form-item
              label="出险地点："
              prop="dangerCounty"
              ref="dangerCounty"
              v-if="permissions.dangerProvince"
            >
              <v-distpicker
                class="distpicker-all-box"
                :province="ruleForm.dangerProvince"
                :city="ruleForm.dangerCity"
                :area="ruleForm.dangerCounty"
                @selected="onSelected"
              ></v-distpicker>
            </el-form-item>
            <el-form-item
              label="出险时间："
              prop="dangerDate"
              ref="dangerDate"
              v-if="permissions.dangerDate"
            >
              <el-date-picker
                v-model="ruleForm.dangerDate"
                type="datetime"
                format="yyyy/MM/dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="年/月/日"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              label="案件责任比例："
              prop="dutyRate"
              ref="dutyRate"
              v-if="permissions.dutyRate"
            >
              <el-input
                v-model="ruleForm.dutyRate"
                placeholder="请填写"
                @input="input_num($event, ruleForm, 'dutyRate', 100)"
              >
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
            <el-form-item
              label="出险类型适用保险条款："
              prop="dangerTypeClause"
              ref="dangerTypeClause"
              v-if="permissions.dangerTypeClause"
            >
              <el-input
                v-model="ruleForm.dangerTypeClause"
                placeholder="请填写"
                maxlength="24"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="设备受损部位："
              prop="damagedParts"
              v-if="permissions.damagedParts"
            >
              <el-input
                v-model="ruleForm.damagedParts"
                placeholder="请填写"
                maxlength="24"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="预估损失金额："
              prop="estimateLoss"
              v-if="permissions.estimateLoss"
              @input="input_num($event, ruleForm, 'estimateLoss', 999999999999)"
            >
              <el-input
                v-model="ruleForm.estimateLoss"
                placeholder="请填写"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="现场联系人："
              prop="siteContactName"
              v-if="permissions.siteContactName"
            >
              <el-input
                v-model="ruleForm.siteContactName"
                placeholder="请填写"
                maxlength="12"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="现场联系电话："
              prop="siteContactPhone"
              v-if="permissions.siteContactPhone"
            >
              <el-input
                v-model="ruleForm.siteContactPhone"
                placeholder="请填写"
                maxlength="11"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="报案时刻现场天气："
              prop="weather"
              v-if="permissions.siteContactPhone"
            >
              <!-- <el-input v-model="ruleForm.weather"
                placeholder="请填写"></el-input> -->
              <el-select
                v-model="ruleForm.weather"
                @change="
                  handleChangeSelect(
                    $event,
                    ruleForm,
                    weatherList,
                    'weather',
                    'dictValue'
                  )
                "
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="dictItem in weatherList"
                  :key="dictItem.dictKey"
                  :label="dictItem.dictValue"
                  :value="dictItem.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="简述事故发生原因："
              prop="accidentCause"
              ref="accidentCause"
              v-if="permissions.accidentCause"
            >
              <el-input
                v-model="ruleForm.accidentCause"
                placeholder="请填写"
                maxlength="50"
              ></el-input>
            </el-form-item>
            <div class="switch-label-box">
              <el-form-item label="是否需要施救：" prop="rescue">
                <span style="margin-right: 12px">是否需要施救</span>
                <textSwitch
                  v-model="ruleForm.rescue"
                  active-text="否"
                  inactive-text="是"
                />
              </el-form-item>
              <el-form-item label="三者案件有无三方证明：" prop="prove">
                <span style="margin-right: 12px">三者案件有无三方证明</span>
                <textSwitch
                  v-model="ruleForm.prove"
                  active-text="否"
                  inactive-text="是"
                />
              </el-form-item>
              <el-form-item
                label="是否一次事故造成损失："
                prop="secondaryAccident"
              >
                <span style="margin-right: 12px">是否一次事故造成损失</span>
                <textSwitch
                  v-model="ruleForm.secondaryAccident"
                  active-text="否"
                  inactive-text="是"
                />
              </el-form-item>
            </div>
            <div style="font-size: 14px" v-if="!ruleForm.secondaryAccident">
              非一次事故造成损失说明
            </div>
            <el-form-item
              label
              v-if="!ruleForm.secondaryAccident"
              prop="notOneDamagedDesc"
              class="not-one-damaged"
            >
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-model="ruleForm.notOneDamagedDesc"
                maxlength="50"
                rows="5"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item
              label="备注"
              v-if="permissions.dangerRemark"
              prop="dangerRemark"
              class="not-one-damaged"
            >
              <el-input
                type="textarea"
                placeholder="请输入内容"
                v-if="permissions.dangerRemark"
                v-model="ruleForm.dangerRemark"
                maxlength="100"
                rows="6"
                show-word-limit
              ></el-input>
            </el-form-item>
            <!-- <div style="font-size: 14px;" ref="dangerRemark"
              v-if="permissions.dangerRemark">备注</div>
            <el-input type="textarea"
              placeholder="请输入内容"
              v-if="permissions.dangerRemark"
              v-model="ruleForm.dangerRemark"
              maxlength="100"
              rows="6"
              show-word-limit></el-input> -->
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="一立科技硬件报警信息" name="3">
          <el-row :gutter="10" class="statistical-box">
            <el-table
              style="width: 100%"
              border
              :data="
                detailData &&
                detailData.hardware &&
                detailData.hardware.hardwareAlarmVO
                  ? detailData.hardware.hardwareAlarmVO
                  : []
              "
            >
              <el-table-column
                prop="date"
                label="序号"
                width="80"
                align="center"
              >
                <template slot-scope="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column
                prop="alarmName"
                label="报警名称"
                align="center"
              ></el-table-column>
              <el-table-column prop="alarmType" label="报警类型" align="center">
                <template slot-scope="scope">
                  {{ scope.row.alarmType == 1 ? "安全风险" : "设备风险" }}
                </template>
              </el-table-column>
              <el-table-column
                prop="alarmSum"
                label="报警总次数"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="alarmSevenSum"
                label="近7天报警次数"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="alarmTodaySum"
                label="出险当日报警次数"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="alarmInterveneSum"
                label="出险当日干预次数"
                align="center"
              ></el-table-column>
            </el-table>
            <div class="statistical-box-decoration">
              <div class="statistical-box-decoration-top">
                <div class="statistical-box-decoration-top-tab">
                  <div
                    :class="{
                      'statistical-box-decoration-top-tab-current':
                        decorationIndex != 1,
                    }"
                    @click="decorationIndex = 1"
                  >
                    附件
                  </div>
                  <div
                    :class="{
                      'statistical-box-decoration-top-tab-current':
                        decorationIndex != 2,
                    }"
                    @click="decorationIndex = 2"
                  >
                    定位
                  </div>
                </div>
                <!-- <div
            class="statistical-box-decoration-top-download"
            v-if="details.attachment && details.attachment.length && isDownload"
            @click="$emit('downloadClick')"
          >
            <img src="@/assets/images/download-icon.png" alt="" srcset="" />
            <span>下载</span>
          </div> -->
              </div>
              <div
                class="statistical-box-decoration-down"
                v-show="decorationIndex == 1"
              >
                <div class="statistical-box-decoration-down-box">
                  <div class="statistical-box-decoration-down-box-title">
                    <div>出险前影像资料</div>
                    <div
                      class="statistical-box-decoration-top-download"
                      v-if="
                        detailData &&
                        detailData.hardware &&
                        detailData.hardware.hardwareAlarmAttachVO &&
                        detailData.hardware.hardwareAlarmAttachVO.length
                      "
                      @click="downLoadClick"
                    >
                      <img
                        src="@/assets/images/download-icon.png"
                        alt=""
                        srcset=""
                      />
                      <span>下载附件</span>
                    </div>
                  </div>
                  <div
                    v-for="(item, index) in detailData &&
                    detailData.hardware &&
                    detailData.hardware.hardwareAlarmAttachVO
                      ? detailData.hardware.hardwareAlarmAttachVO
                      : []"
                    style="width: 100%"
                    :key="index"
                  >
                    <div class="statistical-box-decoration-down-box-time">
                      {{ item[0].createTime }}
                    </div>
                    <div style="display: flex">
                      <el-image
                        class="statistical-box-decoration-down-content"
                        v-for="(item2, index2) in item.filter(
                          (val) => val.filePath.indexOf('.mp4') == -1
                        )"
                        :key="index2"
                        :src="item2.filePath"
                        :preview-src-list="
                          item
                            .filter((val) => val.filePath.indexOf('.mp4') == -1)
                            .map((val) => val.filePath)
                        "
                      >
                      </el-image>

                      <DialogVideo
                        class="statistical-box-decoration-down-content"
                        :videoSrc="item2.filePath"
                        v-for="(item2, index2) in item.filter(
                          (val) => val.filePath.indexOf('.mp4') > -1
                        )"
                        :key="index2 + 999"
                      ></DialogVideo>
                    </div>
                    <!-- <video class="statistical-box-decoration-down-content" controls :src="item" v-for="(item,index) in details.videoAttachment" :key="index + 999"></video> -->
                    <img
                      src="@/assets/images/zwsj-image.png"
                      v-if="
                        !detailData.hardware.hardwareAlarmAttachVO ||
                        !detailData.hardware.hardwareAlarmAttachVO.length
                      "
                      style="height: 170px; margin: auto"
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              </div>
              <div
                v-show="decorationIndex == 2"
                style="padding: 16px"
                class="statistical-box-decoration-down"
              >
                <div
                  class="statistical-box-decoration-down-box-title"
                  style="margin-bottom: 16px"
                >
                  <div>出险时刻定位</div>
                </div>
                <div
                  class="statistical-box-decoration-down"
                  style="
                    border: none !important;
                    overflow: initial;
                    height: 235px;
                  "
                  ref="container"
                ></div>
              </div>
            </div>
          </el-row>
        </AssociationScrollView>
        <AssociationScrollView title="报案信息" name="7">
          <div class="container container-box">
            <el-form-item
              label="报案人姓名："
              prop="reportCasePersonName"
              ref="reportCasePersonName"
              v-if="permissions.reportCasePersonName"
            >
              <el-input
                v-model="ruleForm.reportCasePersonName"
                placeholder="请填写"
                maxlength="12"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="报案人电话："
              prop="reportCasePhone"
              ref="reportCasePhone"
              v-if="permissions.reportCasePhone"
            >
              <el-input
                v-model="ruleForm.reportCasePhone"
                placeholder="请填写"
                maxlength="11"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="报案时间："
              prop="reportCaseTime"
              ref="reportCaseTime"
              v-if="permissions.reportCaseTime"
            >
              <el-date-picker
                v-model="ruleForm.reportCaseTime"
                type="datetime"
                format="yyyy/MM/dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="年/月/日"
              ></el-date-picker>
            </el-form-item>
            <el-form-item
              label="保司报案号："
              prop="realCaseNo"
              v-if="permissions.realCaseNo"
            >
              <el-input
                v-model="ruleForm.realCaseNo"
                placeholder="请填写"
                maxlength="64"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="案件编号："
              prop="caseNo"
              ref="caseNo"
              v-if="ruleForm.caseNo"
            >
              <el-input
                v-model="ruleForm.caseNo"
                placeholder="请填写"
                disabled
                maxlength="64"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="出险设备车架号后8位："
              ref="vinLast"
              v-if="!ruleForm.subjectUnknown && permissions.vinLast"
              prop="vinLast"
            >
              <el-input
                v-model="ruleForm.vinLast"
                placeholder="请填写"
                maxlength="18"
              ></el-input>
            </el-form-item>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="案件相关人员" name="4">
          <el-form-item
            label="理算员："
            prop="adjustmentAssistantId"
            ref="adjustmentAssistantId"
            v-if="permissions.adjustmentAssistant"
          >
            <!-- <EmployeeSelect v-model="ruleForm.adjustmentAssistantId"
              :disabled="ruleForm.adjustmentProcessStatus ==2"
              @change="changeValidateField('adjustmentAssistantId')"
              @changeChecked="handleEmployeeChange($event, ruleForm, 'adjustmentAssistant')" /> -->
            <el-select
              :disabled="
                ruleForm.adjustmentProcessStatus == 2 ||
                ruleForm.adjustmentProcessStatus == 3
              "
              v-model="ruleForm.adjustmentAssistantId"
              @change="
                changeAdjustmentAssistant(
                  $event,
                  adjuster,
                  ruleForm,
                  'adjustmentAssistant'
                )
              "
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in adjuster"
                :key="index"
                :label="item.userName"
                :value="item.userId"
              ></el-option>
            </el-select> </el-form-item
          ><el-form-item
            label="区域经理："
            prop="regionalManagerId"
            ref="regionalManagerId"
            v-if="permissions.regionalManager"
          >
            <!-- <EmployeeSelect v-model="ruleForm.regionalManagerId"
              @change="changeValidateField('regionalManagerId')"
              @changeChecked="handleEmployeeChange($event, ruleForm, 'regionalManager')"
              :disabled="ruleForm.subjectLossProcessStatus ==2 || ruleForm.subjectInspectionProcessStatus ==2" /> -->
            <el-select
              :disabled="
                ruleForm.subjectLossProcessStatus == 2 ||
                ruleForm.subjectLossProcessStatus == 3 ||
                ruleForm.subjectInspectionProcessStatus == 2 ||
                ruleForm.subjectInspectionProcessStatus == 3
              "
              v-model="ruleForm.regionalManagerId"
              @change="
                changeAdjustmentAssistant(
                  $event,
                  areaManager,
                  ruleForm,
                  'regionalManager'
                )
              "
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in areaManager"
                :key="index"
                :label="item.userName"
                :value="item.userId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="跟进人："
            prop="caseFollowerId"
            ref="caseFollowerId"
            v-if="permissions.caseFollower"
          >
            <el-input
              v-model="ruleForm.caseFollower"
              disabled
              placeholder="请填写"
              v-if="$route.query.orderType == 8 || ruleForm.orderType == 8"
            ></el-input>
            <EmployeeSelect
              disabled
              v-else
              v-model="ruleForm.caseFollowerId"
              placement="top"
              @change="changeValidateField('caseFollowerId')"
              @changeChecked="
                handleEmployeeChange($event, ruleForm, 'caseFollower')
              "
            />
          </el-form-item>
        </AssociationScrollView>
      </AssociationScroll>
    </el-form>
    <!-- 地图选择地址 -->
    <positionDialog
      v-model="isPositionDialog"
      type="addressObj"
      :addressValue="addressValue"
      @submit="handleAddressVal"
    />
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import EmployeeSelect from "@/components/EmployeeSelect/index.vue";
import textSwitch from "@/components/textSwitch/index.vue";
import positionDialog from "@/views/policyManagement/components/positionDialog.vue";
import { isCardNo, phoneFun, uploadFile, fileToBase64 } from "@/utils/index";
import {
  dictionaryBatch,
  listScheme,
  downloadSubject,
  businessLicense,
} from "@/api/policy";
import {
  insClaimsCasePage,
  searchPolicyNo,
  getAuditorById,
} from "@/api/claimData";
import DialogVideo from "@/components/DialogVideo";
import VDistpicker from "v-distpicker";
import { downloadZIP } from "@/utils/index";
import { mapState } from "vuex";
export default {
  props: {
    detailData: {
      type: Object,
      default: () => {},
    },
    policySubjectList: {
      type: Array,
      default: () => [],
    },
    policyInsuredList: {
      type: Array,
      default: () => [],
    },
    policyDisabled: {
      type: Boolean,
      default: false,
    },
    permissions: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    detailData: {
      handler(v, o) {
        if (v) {
          // this.excludePolicyId = v.id + '';
          let ruleForm = JSON.parse(JSON.stringify(v));
          ruleForm.policyTime = [
            ruleForm.policyStartDate || "",
            ruleForm.policyEndDate || "",
          ];
          // this.handleReverseDealWith(ruleForm.policyInsuredList);
          // this.handleReverseDealWith(ruleForm.policyHolderList);
          // this.handleReverseDealWith(ruleForm.beneficiaryList);
          if (ruleForm.frameNo) {
            ruleForm.equipment = ruleForm.frameNo;
            this.getInsClaimsCasePage(ruleForm.frameNo);
          }
          if (ruleForm.subjectUnknown) {
            Object.assign(ruleForm, {
              deviceTypeName: "",
              deviceType: "",
              brand: "",
              equipmentType: "",
              frameNo: "",
              curbWeight: "",
              productionDate: "",
              workTimeDay: "",
              workTimeAverageMonth: "",
              subjectUnknown: false,
              equipment: "不能确认",
            });
          }
          Object.assign(this.ruleForm, ruleForm);
          if (this.$route.params.id) {
            this.$emit("currentClaim", JSON.parse(JSON.stringify(ruleForm)));
          }
        }
        this.$forceUpdate();
      },
      deep: true,
      immediate: true,
    },
    decorationIndex: {
      handler(v) {
        if (v == 2) {
          this.initMap();
        }
      },
    },
  },
  data() {
    var validateMobile = (rule, value, callback) => {
      if (!phoneFun(value) && value) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        accidentCause: "",
        adjustmentAssistant: "",
        adjustmentAssistantId: "",
        adjustmentProcessStatus: "",
        brand: "",
        caseFollower: "",
        caseFollowerId: "",
        caseNo: "",
        createUserName: "",
        curbWeight: "",
        damagedParts: "",
        dangerCity: "",
        dangerCounty: "",
        dangerDate: "",
        dangerDetailAddress: "",
        dangerDetailAddressLonlat: "",
        dangerProvince: "",
        dangerRemark: "",
        dangerType: "",
        dangerTypeClause: "",
        dangerTypeId: "",
        deviceType: "",
        dispatchProcessStatus: "",
        dutyRate: "",
        engineNo: "",
        equipmentType: "",
        estimateLoss: "",
        frameNo: "",
        jiShouName: "",
        weather: "",
        jiShouPhone: "",
        isControls: true,
        jiShouExperience: "",
        jiShouIdNumber: "",
        jiShouAge: "",
        dangerTypeDetails: "",
        accidentType: "",
        lossType: "",
        lastStatus: "",
        lastStatusDateTime: "",
        lastStatusDesc: "",
        notOneDamagedDesc: "",
        policyApplicantMode: "",
        policyApplicantStore: "",
        policyCompany: "",
        policyEndDate: "",
        policyFirstInsured: "",
        policyId: "",
        policyInsuranceType: "",
        policyNo: "",
        policyProvince: "",
        policySalesman: "",
        policySecondInsured: "",
        policyStartDate: "",
        productionDate: "",
        workTimeDay: "",
        workTimeAverageMonth: "",
        prove: true,
        realCaseNo: "",
        regionalManager: "",
        regionalManagerId: "",
        reopenProcessStatus: "",
        reportCasePersonName: "",
        reportCasePhone: "",
        reportCaseTime: "",
        rescue: true,
        secondaryAccident: true,
        siteContactName: "",
        siteContactPhone: "",
        status: "",
        statusDatetime: "",
        subjectInspectionProcessStatus: "",
        subjectLossProcessStatus: "",
        suspendReasonName: "",
        suspendReasonRemark: "",
        suspendReasonValue: "",
        suspendReplenishInfo: "",
        suspendReplenishRemark: "",
        vinLast: "",
        workTime: "",
        policyTime: ["", ""],
        subjectUnknown: false,
        equipment: "",
      },
      rules: {
        policyNo: [
          { required: true, message: "请输入保单号", trigger: "change" },
        ],
        adjustmentAssistantId: [
          { required: true, message: "请选择理算员", trigger: "change" },
        ],
        caseFollowerId: [
          { required: true, message: "请选择跟进人", trigger: "change" },
        ],
        regionalManagerId: [
          { required: true, message: "请选择区域经理", trigger: "change" },
        ],
        equipment: [
          { required: true, message: "请选择出险设备", trigger: "change" },
        ],
        workTime: [
          { required: true, message: "请输入小时数", trigger: "change" },
        ],
        dangerTypeId: [
          { required: true, message: "请选择出险类型", trigger: "change" },
        ],
        dangerTypeDetails: [
          { required: true, message: "请选择出险类型细分", trigger: "change" },
        ],
        accidentType: [
          { required: true, message: "请选择事故类型", trigger: "change" },
        ],
        lossType: [
          { required: true, message: "请选择损失种类", trigger: "change" },
        ],
        dutyRate: [
          { required: true, message: "请输入案件责任比例", trigger: "change" },
        ],
        dangerCounty: [
          { required: true, message: "请选择出险地点", trigger: "change" },
        ],
        dangerDetailAddress: [
          { required: true, message: "请输入详细地址", trigger: "change" },
        ],
        dangerDate: [
          { required: true, message: "请选择出险时间", trigger: "change" },
        ],
        dangerTypeClause: [
          {
            required: true,
            message: "请输入出险类型适用保险条款",
            trigger: "change",
          },
        ],
        accidentCause: [
          { required: true, message: "请输入事故发生原因", trigger: "change" },
        ],
        reportCasePersonName: [
          { required: true, message: "请输入报案人姓名", trigger: "change" },
        ],
        reportCasePhone: [
          {
            validator: validateMobile,
            required: true,
            message: "请输入报案人联系方式",
            trigger: "change",
          },
        ],
        siteContactPhone: [
          {
            validator: validateMobile,
            message: "请输入报案人联系方式",
            trigger: "change",
          },
        ],
        reportCaseTime: [
          { required: true, message: "请输入报案时间", trigger: "change" },
        ],
        vinLast: [
          {
            required: true,
            message: "请输入出险设备车架号后8位",
            trigger: "change",
          },
        ],
        notOneDamagedDesc: [
          {
            required: true,
            message: "请输入非一次事故造成损失说明",
            trigger: "change",
          },
        ],
        dangerRemark: [
          { required: true, message: "请输入出险信息备注", trigger: "change" },
        ],
        realCaseNo: [
          { required: true, message: "请输入保司报案号", trigger: "change" },
        ],
      },
      equipment: "",
      policySubjectHistoryList: [],
      dangerTypeList: [],
      applicantStoreList: [],
      vehicleBrandList: [],
      vehicleTypeList: [],
      lossTypeList: [],
      weatherList: [],
      accidentTypeList: [],
      dangerTypeDetailsList: [],

      options: [],
      identCardType: [],
      insuranceCompany: [],
      vehicleBrand: [],
      vehicleType: [],
      attachInsuranceType: [],
      primaryInsuranceType: [],
      applicantStore: [],
      applicantMode: [],
      applicantType: [],
      userOptions: [],
      radio: 1,
      startType: 1,
      isPositionDialog: false,
      isAssociationDialog: false,
      isSubjectDialog: false,
      isExampleDialog: false,
      ExampleTypeName: "identity",
      AssignmentObj: null,
      isIdentityCard: false,
      temporaryVal: "",
      excludePolicyId: "",
      currentClaimData: {},
      addressValue: "",
      areaManager: [],
      adjuster: [],
      decorationIndex: 1,
      mapall: null,
      marker: null,
    };
  },
  components: {
    AssociationScroll,
    AssociationScrollView,
    VDistpicker,
    EmployeeSelect,
    positionDialog,
    textSwitch,
    DialogVideo,
  },
  created() {
    //console.log(this.$store.state.userInfo.deptId);
    this.ruleForm.reportCasePersonName = this.$store.state.userInfo.name;
    this.init();
    this.$once("currentClaim", (e) => {
      let {
        adjustmentAssistantId,
        caseFollowerId,
        regionalManagerId,
        policyApplicantStore,
        policyApplicantMode,
        policyCompany,
        policyNo,
        policyFirstInsured,
        policySecondInsured,
        policySalesman,
        policyTime,
        policyProvince,
        policyInsuranceType,
        subjectUnknown,
        equipment,
        deviceType,
        brand,
        equipmentType,
        frameNo,
        curbWeight,
        productionDate,
        workTimeDay,
        workTimeAverageMonth,
        workTime,
      } = e;
      this.currentClaimData = {
        policy: {
          adjustmentAssistantId,
          caseFollowerId,
          regionalManagerId,
          policyApplicantStore,
          policyApplicantMode,
          policyCompany,
          policyNo,
          policyFirstInsured,
          policySecondInsured,
          policySalesman,
          policyTime,
          policyProvince,
          policyInsuranceType,
        },
        subject: {
          subjectUnknown,
          equipment,
          deviceType,
          brand,
          equipmentType,
          frameNo,
          curbWeight,
          productionDate,
          workTimeDay,
          workTimeAverageMonth,
          workTime,
        },
      };
    });
    if (!this.ruleForm.caseFollowerId || this.$route.query.orderType == 8) {
      this.ruleForm.caseFollowerId = this.$store.state.userInfo.MJUserId;
      this.ruleForm.caseFollower = this.$store.state.userInfo.name;
    }
    this.$store.state.userInfo.deptId
      ? this.getAuditorById(this.$store.state.userInfo.deptId)
      : "";
  },
  computed: {
    findPolicyInsuredList() {
      return this.ruleForm.policyInsuredList.filter(
        (val) => val.playersName && val.cardType
      );
    },
    totalPremium() {
      let num = 0;
      this.ruleForm.policyPlanList.map((val) => {
        num += Number(val.premium);
      });
      this.ruleForm.totalPremium = num.toFixed(2);
      return num;
    },
  },
  methods: {
    init() {
      // 设备类型  dangerType; 投保门店applicantStore; 设备品牌（vehicleBrand）、设备类型（vehicleType）
      dictionaryBatch({
        codes:
          "dangerType,applicantStore,vehicleBrand,vehicleType,dangerTypeDetails,accidentType,lossType,weatherType",
      }).then((res) => {
        if (res && res.data) {
          const {
            dangerType,
            applicantStore,
            vehicleBrand,
            vehicleType,
            dangerTypeDetails,
            accidentType,
            lossType,
            weatherType,
          } = res.data;
          this.dangerTypeList = dangerType || [];
          this.applicantStoreList =
            applicantStore.map((val) => {
              val.dictKey += "";
              return val;
            }) || [];
          this.vehicleBrandList = vehicleBrand || [];
          this.vehicleTypeList = vehicleType || [];
          this.dangerTypeDetailsList = dangerTypeDetails || [];
          this.accidentTypeList = accidentType || [];
          this.lossTypeList = lossType || [];
          this.weatherList = weatherType || [];
        }
      });
    },
    onSelected(e, key) {
      console.log(key);
      if (key) {
        this.ruleForm[key] = e.province.value;
        return false;
      }
      this.ruleForm.dangerProvince = e.province.value;
      this.ruleForm.dangerCity = e.city.value;
      this.ruleForm.dangerCounty = e.area.value;
      this.addressValue =
        e.province.value +
        e.city.value +
        e.area.value +
        this.ruleForm.dangerDetailAddress;
    },
    handleAddressVal(e) {
      this.ruleForm.dangerDetailAddress = e.address;
      this.ruleForm.dangerProvince = e.province;
      this.ruleForm.dangerCity = e.city;
      this.ruleForm.dangerCounty = e.district;
      this.addressValue = e.province + e.city + e.district + e.address;
      this.isPositionDialog = false;
      this.$forceUpdate();
    },
    handleSubmit(type = "") {
      return new Promise((resolve, reject) => {
        if (type == "draft") {
          let ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
          let frameNo = ruleForm.frameNo + "";
          let frameNo1 = frameNo
            ? frameNo.substring(
                frameNo.length - 8 < 0 ? 0 : frameNo.length - 8,
                frameNo.length
              )
            : "";
          // if (frameNo1 != ruleForm.vinLast && !ruleForm.subjectUnknown) {
          //   this.$message.error('出险设备车架号后8位与出险车架号不对应');
          //   this.$refs['frameNo'].$el.scrollIntoView();
          //   reject('校验未通过');
          //   return false;
          // }
          ruleForm.resetObj = this.handleClaimCheck(ruleForm);
          // ruleForm.reportCaseTime ? ruleForm.reportCaseTime = ruleForm.reportCaseTime.split(' ').length > 1 ? ruleForm.reportCaseTime : ruleForm.reportCaseTime + ' 00:00:00' : '';
          // ruleForm.dangerDate ? ruleForm.dangerDate = ruleForm.dangerDate.split(' ').length > 1 ? ruleForm.dangerDate : ruleForm.dangerDate + ' 00:00:00' : '';
          ruleForm.productionDate
            ? (ruleForm.productionDate =
                ruleForm.productionDate.split(" ").length > 1
                  ? ruleForm.productionDate
                  : ruleForm.productionDate + " 00:00:00")
            : "";
          ruleForm.policyTime && ruleForm.policyTime.length
            ? (ruleForm.policyStartDate = ruleForm.policyTime[0])
            : "";
          ruleForm.policyTime && ruleForm.policyTime.length
            ? (ruleForm.policyEndDate = ruleForm.policyTime[1])
            : "";
          resolve(ruleForm);
          return false;
        }
        this.$refs["ruleForm"].validate((valid, validObj) => {
          let ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
          if (valid) {
            let frameNo = ruleForm.frameNo + "";
            let frameNo1 = frameNo.substring(
              frameNo.length - 8 < 0 ? 0 : frameNo.length - 8,
              frameNo.length
            );
            if (frameNo1 != ruleForm.vinLast && !ruleForm.subjectUnknown) {
              this.$message.error("出险设备车架号后8位与出险车架号不对应");
              this.$refs["vinLast"].$el.scrollIntoView();
              reject("校验未通过");
              return false;
            }
            ruleForm.resetObj = this.handleClaimCheck(ruleForm);
            // ruleForm.reportCaseTime ? ruleForm.reportCaseTime = ruleForm.reportCaseTime.split(' ').length > 1 ? ruleForm.reportCaseTime : ruleForm.reportCaseTime + ' 00:00:00' : '';
            // ruleForm.dangerDate ? ruleForm.dangerDate = ruleForm.dangerDate.split(' ').length > 1 ? ruleForm.dangerDate : ruleForm.dangerDate + ' 00:00:00' : '';
            ruleForm.productionDate
              ? (ruleForm.productionDate =
                  ruleForm.productionDate.split(" ").length > 1
                    ? ruleForm.productionDate
                    : ruleForm.productionDate + " 00:00:00")
              : "";
            ruleForm.policyStartDate = ruleForm.policyTime[0];
            ruleForm.policyEndDate = ruleForm.policyTime[1];
            resolve(ruleForm);
          } else {
            let messageList = [];
            // this.$message.error('校验未通过');
            for (const key in validObj) {
              messageList.push(validObj[key][0]);
            }
            if (messageList.length) {
              this.$message.error(messageList[0].message);
              this.$refs[messageList[0].field].$el.scrollIntoView();
            } else {
              reject("校验未通过");
            }
          }
        });
      });
    },
    // 格式化限制数字文本框输入，只能数字或者两位小数
    input_num(e, tiem, key, max, decimal = 2) {
      // 清除"数字"和"."以外的字符
      if (decimal == 0) {
        e = e.replace(/[^\d]/g, "");
      } else {
        e = e.replace(/[^\d.]/g, "");
      }
      // 验证第一个字符是数字
      e = e.replace(/^\./g, "");
      // 只保留第一个, 清除多余的
      e = e.replace(/\.{2,}/g, ".");
      e = e.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      if (decimal == 2) {
        // 只能输入两个小数
        tiem[key] = e.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      } else {
        // 只能输入4个小数
        tiem[key] = e.replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, "$1$2.$3");
      }

      //如果有小数点，不能为类似 1.10的金额
      // if (e.indexOf(".") > 0 && e.indexOf("0") > 2) {
      //   tiem[key] = parseFloat(e);
      // }
      // //如果有小数点，不能为类似 0.00的金额
      // if (e.indexOf(".") > 0 && e.lastIndexOf("0") > 2) {
      //   tiem[key] = parseFloat(e);
      // }
      //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      if (e.indexOf(".") <= 0 && e != "") {
        tiem[key] = parseFloat(e);
      }
      if (e > max) {
        const aa = e + "";
        tiem[key] = parseFloat(aa.slice(0, e.length - 1));
      }
    },
    querySearch(queryString, cb) {
      if (queryString) {
        searchPolicyNo({
          policyNo: queryString,
          orderType: this.$route.query.orderType || "",
          category: this.$route.query.category || "",
        })
          .then((res) => {
            if (res.data && res.data.length) {
              cb(
                res.data.map((val) => {
                  val.value = val.policyNo;
                  return val;
                })
              );
            } else {
              cb([]);
            }
          })
          .catch((err) => {
            cb([]);
          });
      } else {
        cb([]);
      }
    },
    handleSelect(e) {
      this.$parent.getDetail(e.policyId);
    },
    handlePolicyBlur(e) {
      this.ruleForm.policyNo = this.detailData.policyNo || "";
    },
    handleChangeEquipment(e) {
      let data = {
        deviceTypeName: this.policySubjectList[e].deviceTypeName,
        deviceType: this.policySubjectList[e].deviceTypeName,
        brand: this.policySubjectList[e].brandName,
        equipmentType: this.policySubjectList[e].equipmentType,
        frameNo: this.policySubjectList[e].frameNo,
        curbWeight: this.policySubjectList[e].curbWeight,
        productionDate: this.policySubjectList[e].productionDate,
        workTimeDay: this.policySubjectList[e].workTimeDay,
        workTimeAverageMonth: this.policySubjectList[e].workTimeAverageMonth,
        subjectUnknown: false,
        workTime: this.policySubjectList[e].workTime,
      };
      Object.assign(this.ruleForm, data);
      this.getInsClaimsCasePage(data.frameNo);
    },
    changeSubjectUnknown(e) {
      if (e) {
        let data = {
          deviceTypeName: "",
          deviceType: "",
          brand: "",
          equipmentType: "",
          frameNo: "",
          curbWeight: "",
          productionDate: "",
          workTimeDay: "",
          workTimeAverageMonth: "",
          equipment: "不能确认",
        };
        Object.assign(this.ruleForm, data);
        this.policySubjectHistoryList = [];
      } else {
        this.ruleForm.equipment = "";
      }
    },
    getInsClaimsCasePage(frameNo) {
      insClaimsCasePage({
        frameNo: frameNo,
        excludeCaseId: this.ruleForm.id ? [this.ruleForm.id] : [],
        current: 1,
        size: 999,
      }).then((res) => {
        if (res.data && res.data.records && res.data.records.length) {
          this.policySubjectHistoryList = res.data.records;
        } else {
          this.policySubjectHistoryList = [];
        }
      });
    },
    handleReset() {
      this.ruleForm = {
        accidentCause: "",
        adjustmentAssistant: "",
        adjustmentAssistantId: "",
        adjustmentProcessStatus: "",
        brand: "",
        caseFollower: "",
        caseFollowerId: "",
        caseNo: "",
        createUserName: "",
        curbWeight: "",
        damagedParts: "",
        dangerCity: "",
        dangerCounty: "",
        dangerDate: "",
        dangerDetailAddress: "",
        dangerDetailAddressLonlat: "",
        dangerProvince: "",
        dangerRemark: "",
        dangerType: "",
        dangerTypeDetails: "",
        accidentType: "",
        lossType: "",
        jiShouName: "",
        weather: "",
        jiShouPhone: "",
        isControls: true,
        jiShouExperience: "",
        jiShouIdNumber: "",
        jiShouAge: "",
        dangerTypeClause: "",
        dangerTypeId: "",
        deviceType: "",
        dispatchProcessStatus: "",
        dutyRate: "",
        engineNo: "",
        equipmentType: "",
        estimateLoss: "",
        frameNo: "",
        lastStatus: "",
        lastStatusDateTime: "",
        lastStatusDesc: "",
        notOneDamagedDesc: "",
        policyApplicantMode: "",
        policyApplicantStore: "",
        policyCompany: "",
        policyEndDate: "",
        policyFirstInsured: "",
        policyId: "",
        policyInsuranceType: "",
        policyNo: "",
        policyProvince: "",
        policySalesman: "",
        policySecondInsured: "",
        policyStartDate: "",
        productionDate: "",
        workTimeDay: "",
        workTimeAverageMonth: "",
        prove: true,
        realCaseNo: "",
        regionalManager: "",
        regionalManagerId: "",
        reopenProcessStatus: "",
        reportCasePersonName: this.$store.state.userInfo.name,
        reportCasePhone: "",
        reportCaseTime: "",
        rescue: true,
        secondaryAccident: true,
        siteContactName: "",
        siteContactPhone: "",
        status: "",
        statusDatetime: "",
        subjectInspectionProcessStatus: "",
        subjectLossProcessStatus: "",
        suspendReasonName: "",
        suspendReasonRemark: "",
        suspendReasonValue: "",
        suspendReplenishInfo: "",
        suspendReplenishRemark: "",
        vinLast: "",
        workTime: "",
        policyTime: [
          this.detailData.policyStartDate || "",
          this.detailData.policyEndDate || "",
        ],
        subjectUnknown: false,
        equipment: this.detailData.frameNo ? this.detailData.frameNo : "",
        ...JSON.parse(JSON.stringify(this.detailData)),
      };
    },
    handleEmployeeChange(e, item, key) {
      //e.userDept 部门id
      this.getAuditorById(e.userDept);
      item[key] = e.title;
    },
    handleChangeSelect(e, item, list, key, valueKey) {
      let value = list.filter((val) => val.dictKey == e)[0];
      if (value && key == "dangerType") {
        item[key] = value[valueKey];
        value.remark
          ? (this.dangerTypeDetailsList = value.remark.split(",").map((val) => {
              return {
                dictValue: val,
                dictKey: val,
              };
            }))
          : (this.dangerTypeDetailsList = []);
      } else if (value) {
        item[key] = value[valueKey];
      } else {
      }
      this.$forceUpdate();
    },
    handleClaimCheck(e) {
      let {
        adjustmentAssistantId,
        caseFollowerId,
        regionalManagerId,
        policyApplicantStore,
        policyApplicantMode,
        policyCompany,
        policyNo,
        policyFirstInsured,
        policySecondInsured,
        policySalesman,
        policyTime,
        policyProvince,
        policyInsuranceType,
        subjectUnknown,
        equipment,
        deviceType,
        brand,
        equipmentType,
        frameNo,
        curbWeight,
        productionDate,
        workTimeDay,
        workTimeAverageMonth,
        workTime,
      } = e;
      let claimData = {
        policy: {
          adjustmentAssistantId,
          caseFollowerId,
          regionalManagerId,
          policyApplicantStore,
          policyApplicantMode,
          policyCompany,
          policyNo,
          policyFirstInsured,
          policySecondInsured,
          policySalesman,
          policyTime,
          policyProvince,
          policyInsuranceType,
        },
        subject: {
          subjectUnknown,
          equipment,
          deviceType,
          brand,
          equipmentType,
          frameNo,
          curbWeight,
          productionDate,
          workTimeDay,
          workTimeAverageMonth,
          workTime,
        },
      };
      if (
        JSON.stringify(claimData.policy) !=
        JSON.stringify(this.currentClaimData.policy)
      ) {
        return "policy";
      } else if (
        JSON.stringify(claimData.subject) !=
        JSON.stringify(this.currentClaimData.subject)
      ) {
        return "subject";
      } else {
        return false;
      }
    },
    changeValidateField(type) {
      this.$refs["ruleForm"].validateField(type);
    },
    getAuditorById(ids) {
      //e.userDept 部门id
      getAuditorById({ depts: ids }).then(
        (res) => {
          let { adjuster, areaManager } = res.data;
          this.areaManager = areaManager;
          this.adjuster = adjuster;
        },
        (err) => {
          this.areaManager = [];
          this.adjuster = [];
        }
      );
    },
    changeAdjustmentAssistant(e, list, item, key) {
      let value = list.filter((val) => val.userId == e)[0];
      item[key] = value.userName;
    },
    downLoadClick() {
      let urls = [];
      if (
        !this.detailData.hardware.hardwareAlarmAttachVO ||
        !this.detailData.hardware.hardwareAlarmAttachVO.length
      ) {
        this.$message.error("暂无附件可下载");
        return false;
      }
      this.detailData.hardware.hardwareAlarmAttachVO.map((val, ind) => {
        val.map((val2, ind2) => {
          urls.push({
            url: val2.filePath,
            name: val2.filePath.split("/")[val2.filePath.split("/").length - 1],
          });
          //  + '.' + val2.filePath.split('.')[val2.filePath.split('.').length -1 ]
          if (
            ind == this.detailData.hardware.hardwareAlarmAttachVO.length - 1 &&
            ind2 ==
              this.detailData.hardware.hardwareAlarmAttachVO[ind].length - 1
          ) {
            this.$nextTick(() => {
              downloadZIP(
                urls,
                `${this.detailData.hardware.hardwareVehicleVO.plate}-${
                  this.detailData.caseFromSourceType == 1
                    ? "小程序报案"
                    : "终端报案"
                }-${
                  this.detailData.hardware.hardwareVehicleVO.accidentTime
                }.zip`
              );
            });
          }
        });
      });
    },
    initMap() {
      const that = this;
      let Lng = "";
      let Lat = "";
      console.log(this.detailData);
      if (
        this.detailData &&
        this.detailData.hardware &&
        this.detailData.hardware.hardwareAlarmVO &&
        this.detailData.hardware.hardwareAlarmVO.length
      ) {
        Lng = this.detailData.hardware.hardwareAlarmVO[0].lon;
        Lat = this.detailData.hardware.hardwareAlarmVO[0].lat;
      } else {
        this.$message.error("暂无报警定位信息");
        return false;
      }
      var content = [
        `<div style='display:flex;width:100%;margin-bottom:16px;min-width:230px;'><div style='font-size:14px;color: #999999;margin-right:12px;'>设备名称</div><div style='font-size:14px;color: #333;'>${this.detailData.hardware.hardwareVehicleVO.plate}</div></div>`,
        `<div style='display:flex;width:100%;margin-bottom:16px;min-width:230px;'><div style='font-size:14px;color: #999999;margin-right:12px;'>报警类型</div><div style='font-size:14px;color: #333;'>${
          this.detailData.hardware.hardwareAlarmVO[0]
            ? this.detailData.hardware.hardwareAlarmVO[0].alarmName
            : "-"
        }</div></div>`,
        `<div style='display:flex;width:100%;margin-bottom:16px;min-width:230px;'><div style='font-size:14px;color: #999999;margin-right:12px;'>报警时间</div><div style='font-size:14px;color: #333;'>${
          this.detailData.hardware.hardwareAlarmVO[0]
            ? this.detailData.hardware.hardwareAlarmVO[0].createTime
            : "-"
        }</div></div>`,
        `<div style='display:flex;width:100%;min-width:230px;'><div style='font-size:14px;color: #999999;margin-right:12px;'>具体地址</div><div style='font-size:14px;color: #333;'>${
          this.detailData.hardware.hardwareAlarmVO[0]
            ? this.detailData.hardware.hardwareAlarmVO[0].alarmProvince
            : "-"
        }${
          this.detailData.hardware.hardwareAlarmVO[0]
            ? this.detailData.hardware.hardwareAlarmVO[0].alarmCity
            : "-"
        }${
          this.detailData.hardware.hardwareAlarmVO[0]
            ? this.detailData.hardware.hardwareAlarmVO[0].alarmArea
            : "-"
        }</div></div>`,
      ];

      // 创建 infoWindow 实例
      var infoWindow = new AMap.InfoWindow({
        content: content.join(""), //传入 dom 对象，或者 html 字符串
        offset: new AMap.Pixel(0, -32),
      });

      if (that.mapall) {
        that.mapall.setCenter([Lng, Lat]);
        that.mapall.setZoom(15);
        that.mapall.remove(this.marker);
        // 创建一个 Marker 实例：
        this.marker = new AMap.Marker({
          position: new AMap.LngLat(Lng, Lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          icon: "//yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/positioning-marker.png", // 添加 Icon 图标 URL
          offset: new AMap.Pixel(-20, -40),
        });

        // 将创建的点标记添加到已有的地图实例：
        that.mapall.add(this.marker);
        // 打开信息窗体
        infoWindow.open(that.mapall, [Lng, Lat]);
      } else {
        var map = new AMap.Map(that.$refs.container, {
          resizeEnable: true,
          center: [Lng, Lat],
          zoom: 15,
        });
        that.mapall = map;
        // 创建一个 Marker 实例：
        this.marker = new AMap.Marker({
          position: new AMap.LngLat(Lng, Lat), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          icon: "//yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/positioning-marker.png", // 添加 Icon 图标 URL
          offset: new AMap.Pixel(-20, -40),
        });

        // 将创建的点标记添加到已有的地图实例：
        that.mapall.add(this.marker);
        // 打开信息窗体
        infoWindow.open(that.mapall, [Lng, Lat]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
}
.container-box {
  padding: 18px 12px 0;
  border-top: 2px dashed #dedede;
  .inline-input {
    width: 100%;
  }
  ::v-deep .el-input__inner[disabled],
  .el-input__inner:disabled,
  .el-input__inner.disabled {
    -webkit-text-fill-color: #858585 !important;
    color: #858585 !important;
    background: #f5f5f5 !important;
    border-color: #ccc !important;
    -webkit-opacity: 1 !important;
    opacity: 1 !important;
    cursor: not-allowed;
  }
  ::v-deep .el-range-editor.is-disabled {
    -webkit-text-fill-color: #858585 !important;
    color: #858585 !important;
    background: #f5f5f5 !important;
    border-color: #ccc !important;
    -webkit-opacity: 1 !important;
    opacity: 1 !important;
    cursor: not-allowed;
  }
  //   .el-range-editor.is-disabled
}
.el-collapse-box {
  height: 100%;
  .view-title {
    flex: 1;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      height: 48px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      img {
        width: 6px;
        height: 24px;
        margin-right: 8px;
      }
    }
    &-right {
      display: flex;
      align-items: center;
    }
    &-float {
      position: fixed;
      background-color: #ffffff;
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      padding: 12px 24px 0;
      margin: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      z-index: 3;
    }
  }
  .el-collapse {
    border: none;
    .el-collapse-item {
      margin-bottom: 10px;
      padding: 24px;
      border-radius: 8px;
      border: none;
      background-color: #fff;
      overflow: hidden;
      ::v-deep .el-collapse-item__arrow {
        display: none;
      }
    }
  }
  .el-select {
    width: 100%;
  }
  ::v-deep .el-descriptions-item__cell {
    padding-bottom: 20px;
  }
  ::v-deep .el-form-item {
    width: 30%;
    .el-form-item__content {
      width: 70%;
    }
  }
  @media (max-width: 1500px) {
    ::v-deep .el-form-item {
      width: 45%;
      .el-form-item__content {
        width: 85%;
      }
    }
  }
  @media (max-width: 1000px) {
    ::v-deep .el-form-item {
      width: 100%;
      .el-form-item__content {
        width: 90%;
      }
    }
  }
  .container-box-custom-label {
    ::v-deep .el-form-item__label {
      width: 70%;
      display: flex;
    }
    &-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
  .switch-label-box {
    ::v-deep .el-form-item__label {
      display: none;
    }
  }
  .plan-table {
    ::v-deep .el-form-item {
      width: 100%;
      .el-form-item__content {
        width: 100%;
      }
      .el-icon--right {
        margin-left: 0;
      }
    }
  }
  ::v-deep .el-date-editor {
    width: 100%;
  }
  .input-customization {
    ::v-deep .el-input__inner {
      padding-right: 72px;
    }
  }
  .certificate-label {
    ::v-deep .el-form-item__label {
      width: 70%;
    }
    .certificate-box {
      display: flex;
      justify-content: space-between;
      &-right {
        display: flex;
        align-items: center;
        .example {
          font-size: 12px;
          color: #0080ff;
          margin-right: 8px;
          cursor: pointer;
        }
        .label-button {
          height: 24px;
          line-height: 24px;
          font-size: 14px;
          padding: 0 6px;
          color: #ffaa00;
          background: rgba(255, 170, 0, 0.16);
          border-radius: 4px;
          border: 1px solid #ffaa00;
          cursor: pointer;
        }
      }
    }
  }
  .insurance-company {
    display: flex;
    justify-content: flex-end;
    max-width: 1000px;
    font-weight: bold;
    color: #4278c9;
    font-size: 16px;
    margin-top: 16px;
  }
  .insurance-clause {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 1000px;
    color: #00bc0d;
    font-size: 14px;
    border-top: none;
    border: 1px solid #ebeef5;
    cursor: pointer;
  }
  .input-position {
    .input-suffix {
      position: relative;
      width: 44px;
      height: 100%;
      border-left: 1px solid #dcdfe6;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        transform: translate(-50%, -50%);
      }
    }
    ::v-deep .el-input__inner {
      padding-right: 48px;
    }
    ::v-deep .el-input.is-disabled .el-input__inner {
      -webkit-text-fill-color: #c0c4cc !important;
      background: #f5f7fa !important;
      -webkit-opacity: 1 !important;
      opacity: 1 !important;
      cursor: not-allowed;
    }
  }
  .statistical-box {
    // width: 820px;
    padding: 24px 6px;
    &-title {
      position: relative;
      padding-left: 13px;
      &-text {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      &-time {
        color: #cccccc;
        font-size: 12px;
      }
    }
    &-title::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background: #4278c9;
      border-radius: 3px;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 24px;
      > div {
        width: 46%;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        > div {
          width: 112px;
          color: #999;
          margin-bottom: 23px;
          margin-right: 16px;
          text-align: right;
        }
        span {
          flex: 1;
          color: #333333;
        }
      }
    }
    &-decoration {
      &-top {
        display: flex;
        justify-content: space-between;
        &-tab {
          display: flex;
          div {
            width: 88px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 14px;
            color: #333333 !important;
            cursor: pointer;
            border-radius: 8px 8px 0px 0px;
            border: 1px solid #cccccc !important;
            border-bottom: none;
          }
          &-current {
            color: #333 !important;
            border: 1px solid #dee3e6 !important;
            background: #dee3e6 !important;
          }
        }
        &-download {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #0080ff;
          img {
            width: 14px;
            height: 14px;
          }
          span {
            margin-left: 2px;
            text-decoration: underline;
          }
        }
      }
      &-down {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-around;
        padding: 16px 0;
        height: 290px;
        overflow: auto;
        border-radius: 0px 8px 8px 8px;
        border: 1px solid #dee3e6 !important;
        &-content {
          width: 128px;
          height: 80px;
          margin-bottom: 8px;
          margin-left: 15px;
          border-radius: 4px;
        }
        &-box {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          &-time {
            width: 153px;
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            color: #ffffff;
            padding-left: 8px;
            margin: 8px;
            margin-left: 16px;
            background: #4278c9;
          }
          &-title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            padding: 0 16px;
            margin-bottom: 8px;
          }
        }
      }
    }
    .el-table {
      margin-bottom: 22px;
      .has-gutter {
        tr {
          .el-table__cell {
            background-color: #f2f7fd;
            font-size: 14px;
            color: #666666;
            font-weight: 400;
          }
          .is-leaf {
            border-bottom: 1px solid #dee3e6;
          }
        }
      }
      tr {
        .el-table__cell {
          font-size: 14px;
          color: #333;
          font-weight: 400;
        }
        .is-leaf {
          border-bottom: 1px solid #dee3e6;
        }
      }
    }
    .amap-info-content {
      padding: 16px 24px;
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: 0px 3px 6px 1px rgba(51, 51, 51, 0.1);
      border-radius: 10px;
    }
    .amap-info-sharp {
      display: none;
    }
  }
}
.clause-title {
  margin: 16px 0;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.not-one-damaged {
  width: 100% !important;
  ::v-deep .el-form-item__content {
    width: 100% !important;
  }
}
</style>
<style lang="scss">
.policy-label-style {
  font-size: 14px;
  color: #333333;
  margin-bottom: 12px;
}
.distpicker-address-wrapper select {
  width: 100%;
  border-radius: 4px;
  padding: 0;
  height: 32px;
  font-size: 13px;
  min-width: 80px;
}
.distpicker-all-box select {
  width: 30%;
}
.hide-picker label:nth-of-type(1) {
  display: none;
}
.popper-customization {
  background: rgba(255, 170, 0, 0.16) !important;
  color: #ffaa00 !important;
  border: 1px solid #ffaa00 !important;
  // .popper__arrow::after {
  //   border-top-color: #747574;
  // }
}
.popper-customization[x-placement^="top"] .popper__arrow::after {
  border-top-color: #ffaa00 !important;
}
.popper-customization[x-placement^="top"] .popper__arrow {
  border-top-color: #ffaa00 !important;
}
.el-collapse-box {
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    // overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__body {
    tr {
      position: relative;
    }
    // .table-del {
    //   position: absolute;
    //   top: 50%;
    //   right: -50px;
    //   transform: translateY(-50%);
    //   font-size: 30px;
    //   color: #c94242;
    //   cursor: pointer;
    // }
    .el-form-item {
      margin: 0;
    }
  }
  .plan-table {
    .has-gutter,
    .el-table__fixed-header-wrapper {
      tr {
        overflow: hidden;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
  }
}
</style>
